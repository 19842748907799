import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  wrapper: css`
    ${baseStyles.wrapper}
  `,
  info: css<{ isMobile: boolean; infoWidth: number }>`
    ${baseStyles.info}
  `,
  infoText: css`
    ${baseStyles.infoText}
  `,
  modal: css`
    ${baseStyles.modal}
  `,
  arrowTop: css`
    ${baseStyles.arrowTop}
  `,
  closeIconWrapper: css`
    ${baseStyles.closeIconWrapper}
  `
};
