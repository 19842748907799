import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  descriptionContainer: css<{ containerHeight: number; isExpanded: boolean }>`
    border-radius: 4px;
    border: 1px solid rgb(219, 219, 219);
    display: flex;
    margin-bottom: 16px;
    margin-top: 16px;
    max-height: ${props => (props.isExpanded ? '100%' : `${props.containerHeight}px`)};
    overflow: hidden;
    padding: 16px;
    padding-bottom: 0;
    width: 100%;
  `,
  expandButtonContainer: css<{ buttonHeight: number }>`
    align-items: center;
    background-color: white;
    border-top-color: rgb(219, 219, 219);
    border-top-width: 1px;
    border-top: solid;
    bottom: 0;
    display: flex;
    height: ${props => `${props.buttonHeight}px`};
    justify-content: center;
    left: 0;
    margin-left: 0px;
    position: absolute;
    right: 0;
  `,
  overflowWrapper: css<{ isNativeApp: boolean; marginBottom: number }>`
    /* Honestly not sure why hidden does not work properly on native here, using scroll in this case is a work around to solve a clipping issue */
    overflow: ${props => (props.isNativeApp ? 'scroll' : 'hidden')};
    padding-bottom: 16px;
    margin-bottom: ${props => `${props.marginBottom}px`};
  `,
  expandButton: css`
    cursor: pointer;
  `
};
