import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  fundraiserMetricsContainer: css`
    ${baseStyles.fundraiserMetricsContainer}
  `,
  infoContainer: css`
    ${baseStyles.infoContainer}
  `,
  verticalDivider: css`
    ${baseStyles.verticalDivider}
  `,
  metricsText: css`
    ${baseStyles.metricsText}
  `
};
