import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css`
    display: flex;
    margin-top: 16px;
  `,

  reservedSeatingWrapper: css<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '100%' : '576px')};
    height: ${({ isMobile }) => (isMobile ? '132px' : '76px')};
    border: 1px solid #ebebeb;
    border-radius: 4px;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'space-between')};
    align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
    padding-right: 16px;
    padding-left: 16px;
    padding-top: ${({ isMobile }) => (isMobile ? '0px' : '16px')};
    padding-bottom: ${({ isMobile }) => (isMobile ? '0px' : '16px')};
  `,
  textWrapper: css<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '144px' : '144px')};
    height: ${({ isMobile }) => (isMobile ? '44px' : '44px')};
    padding-top: ${({ isMobile }) => (isMobile ? '16px' : '0px')};
    padding-bottom: ${({ isMobile }) => (isMobile ? '16px' : '0px')};
    justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
    height: auto;
  `,
  leftSideWrapper: css<{ isMobile: boolean }>`
    flex-direction: row;
  `,
  unavailableWrapper: css<{ isMobile: boolean }>`
    width: 96px;
    height: 24px;
    background-color: rgb(255, 215, 217);
    justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'center')};
    align-self: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
    border-radius: 16px;
    margin-left: ${({ isMobile }) => (isMobile ? '84px' : '124px')};
  `,
  buttonWrapper: css<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '100%' : '159px')};
    margin-bottom: ${({ isMobile }) => (isMobile ? '16px' : '0px')};
  `
};
