import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  eventTicketItemContainer: css`
    ${baseStyles.eventTicketItemContainer}
  `,
  eventTicketTextContainer: css`
    ${baseStyles.eventTicketTextContainer}
  `,
  eventNotAccessContainer: css`
    ${baseStyles.eventNotAccessContainer}
  `,
  lowTicketWarningWrapper: css`
    ${baseStyles.lowTicketWarningWrapper}
  `,
  lowTicketWarningContainer: css`
    ${baseStyles.lowTicketWarningContainer}
  `
};
