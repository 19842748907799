import React, { useCallback, useState } from 'react';
import { LayoutChangeEvent, View } from 'react-native';

import { ProductSeating, Promotion } from '@gf/cross-platform-lib/interfaces';
import {
  eventDetailsPage,
  testProperties,
  useCurrencyFormatter,
  correctHexColor,
  isNearWhite,
  isStateUsingEnhancedFee
} from '@gf/cross-platform-lib/utils';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';

import { NumberInput, StyledText } from '@gf/cross-platform-lib/components';
import { LowTicketWarning } from '../LowTicketWarning';
import { InputChangeType } from '@gf/cross-platform-lib/components/NumberInput/contants';
import { AccessCodeType } from '@gf/cross-platform-lib/constants';
import { EventAccessCodeItem } from '../EventAccessCode/EventAccessCodeItem';

import { styles } from './EventTicketItem.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import isEmpty from 'lodash/isEmpty';
import { EventCart, SeasonCart } from '@gf/cross-platform-lib/models';
import { getSchoolLimit, getTotalQuantity, isDisabledIncreaseButton } from './helpers';
import { PriceDisplay } from './PriceDisplay';

const EventTicketItemContainer = styled(View)`
  ${styles.eventTicketItemContainer}
`;
const EventTicketTextContainer = styled(View)`
  ${styles.eventTicketTextContainer}
`;
const EventNotAccessContainer = styled(View)`
  ${styles.eventNotAccessContainer}
`;
const LowTicketWarningWrapper = styled(View)`
  ${styles.lowTicketWarningWrapper}
`;
const LowTicketWarningContainer = styled(View)`
  ${styles.lowTicketWarningContainer}
`;

const MAX_ONE_LINE_HEIGHT = 25;

interface EventTicketsProps {
  accessCode?: string;
  accessCodeType: AccessCodeType;
  quantity?: number;
  ticket: ProductSeating;
  ticketsLeftInEvent?: number;
  updateTicketsToQuantity: (ticket: ProductSeating, newQuantity: number, changeType?: InputChangeType) => void;
  numberInputState?: boolean;
  accessCodes?: Promotion[];
  ticketsToQuantity?: Map<ProductSeating, number>;
  eventDetailCartEvent: EventCart | SeasonCart;
  handleUpdateTicketUpdateAt: (ticketId: string) => void;
  financialSchoolState: string;
  isFirstTicket: boolean;
}

export const EventTicketItem = ({
  accessCode,
  accessCodeType,
  quantity = 0,
  ticket,
  updateTicketsToQuantity,
  numberInputState = true,
  eventDetailCartEvent,
  handleUpdateTicketUpdateAt,
  financialSchoolState,
  isFirstTicket
}: EventTicketsProps) => {
  const formatCurrencyAndDropTrailingZeros = useCurrencyFormatter().formatAndDropTrailingZeros;
  const { isMobile } = useMediaQuery();
  const { eventTotalRemainingQuantity, productSalesMap, schoolSalesMap } = eventDetailCartEvent.eventSalesInfo || {};

  const updateTicketQuantity = (newQuantity: number, oldValue?: number, changeType?: InputChangeType) => {
    handleUpdateTicketUpdateAt(ticket.id);
    updateTicketsToQuantity(ticket, newQuantity, changeType);
  };

  const limitTypes: { [p: string]: number } = {
    ticketLimit: productSalesMap[ticket.id]?.remainingQuantity || 0,
    schoolLimit:
      !isEmpty(schoolSalesMap) && getSchoolLimit(eventDetailCartEvent.schoolsTicket, ticket.schoolHuddleId)
        ? getSchoolLimit(eventDetailCartEvent.schoolsTicket, ticket.schoolHuddleId) -
          (schoolSalesMap[ticket.schoolHuddleId] || 0)
        : 0,
    eventLimit: eventTotalRemainingQuantity || 0,
    ticketLimitPerOrder: ticket.ticketLimitPerOrder || 0,
    eventOrderLimit: eventDetailCartEvent.ticketLimitPerOrder || 0
  };
  const [isTextWrapped, setIsTextWrapped] = useState(false);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setIsTextWrapped(height > MAX_ONE_LINE_HEIGHT);
  }, []);

  return (
    <EventTicketItemContainer
      ticketColor={correctHexColor(ticket.customColor)}
      isNearWhite={ticket.customColor ? isNearWhite(ticket.customColor) : false}
      isMobile={isMobile}
      isTextWrapped={isTextWrapped}
      isFirstTicket={isFirstTicket}
    >
      <EventNotAccessContainer>
        <EventTicketTextContainer isMobile={isMobile}>
          <StyledText
            typeToken='label03'
            style={{ fontFamily: 'Roboto-Bold', flexShrink: 1 }}
            {...testProperties(eventDetailsPage.product.name)}
            onLayout={onLayout}
          >
            {ticket.name}
          </StyledText>
          {ticket.packCount && ticket.packCount > 1 && (
            <StyledText typeToken='label01'>{`(${ticket.packCount} ${
              ticket.productType === 'MOBILEPASS' ? 'passes' : 'tickets'
            } per pack)`}</StyledText>
          )}
          <PriceDisplay
            totalPrice={formatCurrencyAndDropTrailingZeros(ticket.price + ticket.fee)}
            ticketPrice={formatCurrencyAndDropTrailingZeros(ticket.price)}
            feePrice={ticket.fee ? formatCurrencyAndDropTrailingZeros(ticket.fee) : null}
            enhancedFeeEnabled={isStateUsingEnhancedFee(financialSchoolState)}
          />
        </EventTicketTextContainer>
        <LowTicketWarningContainer isMobile={isMobile}>
          {ticket.productSales?.remainingQuantity! <= 10 && (
            <LowTicketWarningWrapper isMobile={isMobile}>
              <LowTicketWarning isEvent={false} limit={ticket.limit!} />
            </LowTicketWarningWrapper>
          )}
          <NumberInput
            onChangeQuantity={updateTicketQuantity}
            disabled={accessCodeType === AccessCodeType.LOCKED || !numberInputState}
            disabledIncreaseButton={isDisabledIncreaseButton(
              limitTypes,
              quantity,
              ticket.packCount || 1,
              getTotalQuantity(eventDetailCartEvent.tickets)
            )}
            size={'lg'}
            value={quantity}
            width={isMobile ? 128 : 184}
            {...testProperties(eventDetailsPage.product.qty)}
          />
        </LowTicketWarningContainer>
      </EventNotAccessContainer>
      {accessCodeType !== AccessCodeType.NONE ? <EventAccessCodeItem accessCode={accessCode} /> : null}
    </EventTicketItemContainer>
  );
};

export default EventTicketItem;
