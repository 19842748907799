import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  venueLocationContainer: css`
    ${baseStyles.venueLocationContainer}
  `,
  venueLocationDetailsContainer: css`
    ${baseStyles.venueLocationDetailsContainer}
  `,
  addressContainer: css`
    ${baseStyles.addressContainer}
  `,
  getDirectionsButton: css<{ isMobile: boolean }>`
    ${baseStyles.getDirectionsButton}
  `
};
