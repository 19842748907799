import React from 'react';
import { DefaultSkeletonComponent } from '@gf/cross-platform-lib/components';

export type GfSkeletonProviderProps = {
  children: React.ReactElement;
  show: boolean;
  skeletonComponent?: React.ReactNode;
};

export const GfSkeletonProvider = ({ children, show, skeletonComponent }: GfSkeletonProviderProps) => {
  const skeleton = skeletonComponent || <DefaultSkeletonComponent />;
  return show ? skeleton : children;
};
