import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  eventSoltOutContainer: css`
    ${baseStyles.eventSoltOutContainer}
  `,
  soldOutTextPill: css`
    ${baseStyles.soldOutTextPill}
  `,
  titleWrapper: css`
    ${baseStyles.titleWrapper}
  `
};
