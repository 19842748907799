import React, { useState } from 'react';
import { View, Pressable } from 'react-native';

import { styles } from './FundraiserDescriptionContainer.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { FundraiserDescription } from '../FundraiserDescription';
import { FundraiserMetrics } from '../FundraiserMetrics';
import { StyledText } from '@gf/cross-platform-lib/components/StyledText';
import { testProperties } from '@gf/cross-platform-lib/utils';

const DescriptionContainer = styled(View)`
  ${styles.descriptionContainer}
`;

const OverflowWrapper = styled(View)`
  ${styles.overflowWrapper}
`;

const ExpandButtonContainer = styled(Pressable)`
  ${styles.expandButtonContainer}
`;

const ExpandButton = styled(View)`
  ${styles.expandButton}
`;

export type FundraiserDescriptionContainerProps = {
  description?: string;
  donationsMade: number;
  daysLeft: number;
};

const platformHeights = {
  mobile: 180,
  desktop: 400
};

const expandButtonHeight = 50;

export const FundraiserDescriptionContainer = ({
  description,
  donationsMade,
  daysLeft
}: FundraiserDescriptionContainerProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [doesDescriptionOverflow, setDoesDescriptionOverflow] = useState<boolean>(false);
  const { isDesktop, isNativeApp } = useMediaQuery();

  if (!description) return null;
  return (
    <DescriptionContainer
      containerHeight={isDesktop ? platformHeights.desktop : platformHeights.mobile}
      isExpanded={isExpanded}
    >
      <OverflowWrapper
        isNativeApp={isNativeApp}
        marginBottom={isExpanded ? expandButtonHeight : 0}
        {...testProperties('description-field')}
      >
        <FundraiserDescription
          description={description}
          setDoesDescriptionOverflow={setDoesDescriptionOverflow}
          buttonHeight={expandButtonHeight}
          containerHeight={isDesktop ? platformHeights.desktop : platformHeights.mobile}
        />
        {(donationsMade > 0 || daysLeft > 0) && <FundraiserMetrics donationsMade={donationsMade} daysLeft={daysLeft} />}
      </OverflowWrapper>
      {doesDescriptionOverflow && (
        <ExpandButtonContainer
          buttonHeight={expandButtonHeight}
          onPress={() => setIsExpanded(!isExpanded)}
          onHoverIn={() => setIsHovered(true)}
          onHoverOut={() => setIsHovered(false)}
        >
          <ExpandButton style={{ opacity: isHovered ? 0.75 : 1 }}>
            <StyledText typeToken='label02' color='textPrimary'>
              Show {isExpanded ? 'Less -' : 'More +'}
            </StyledText>
          </ExpandButton>
        </ExpandButtonContainer>
      )}
    </DescriptionContainer>
  );
};

export default FundraiserDescriptionContainer;
