import React from 'react';
import { styles } from './EventTickets.styles';
import styled from '@gf/cross-platform-lib/styled-components';

import { View } from 'react-native';
import { AccessCodeInformation, ProductSalesMap, ProductSeating, Promotion } from '@gf/cross-platform-lib/interfaces';
import { EventTicketItem } from '../EventTicketItem';
import { filterTickets } from '../../util';
import { InputChangeType } from '@gf/cross-platform-lib/components/NumberInput/contants';
import { EventTicketsAccessCodeOnly } from '../EventAccessCode/EventTicketsAccessCodeOnly';
import { AccessCodeType } from '@gf/cross-platform-lib/constants';
import { isSaleTimeTicket } from '@gf/cross-platform-lib/hooks';
import uniqBy from 'lodash/uniqBy';
import { EventCart } from '@gf/cross-platform-lib/models';

const EventTicketsContainer = styled(View)`
  ${styles.eventTicketsContainer}
`;

interface EventTicketsProps {
  financialSchoolState: string;
  eventTicketLimit?: number;
  prodIdsToAccessCodes: Map<number, AccessCodeInformation>;
  productSalesMap?: ProductSalesMap;
  updateTicketsToQuantity: (ticket: ProductSeating, newQuantity: number, changeType?: InputChangeType) => void;
  ticketsToQuantity: Map<ProductSeating, number>;
  numberInputState: boolean;
  accessCodes?: Promotion[];
  eventDetailCartEvent: EventCart;
  handleUpdateTicketUpdateAt: (ticketId: string) => void;
}

export const EventTickets = ({
  financialSchoolState,
  eventTicketLimit,
  prodIdsToAccessCodes,
  productSalesMap,
  updateTicketsToQuantity,
  ticketsToQuantity,
  numberInputState,
  eventDetailCartEvent,
  handleUpdateTicketUpdateAt
}: EventTicketsProps) => {
  const { tickets, accessCodeTickets } = filterTickets(
    Array.from(ticketsToQuantity.keys()).filter(ticket =>
      isSaleTimeTicket(ticket.salesStartDateTime, ticket.salesEndDateTime, ticket.timezone)
    ),
    productSalesMap
  );
  const totalNumberTickets = Array.from(ticketsToQuantity.entries()).reduce((acc, curr) => {
    const [ticket, qty] = curr;
    const { packCount = 0 } = ticket;
    const totalQty = packCount > 1 ? qty * packCount : qty;
    return acc + totalQty;
  }, 0);
  const ticketsLeftInEvent = eventTicketLimit && eventTicketLimit - totalNumberTickets;

  const onlyAccessCodeTickets = tickets.length === 0 && accessCodeTickets.length > 0 && prodIdsToAccessCodes.size === 0;

  return !onlyAccessCodeTickets ? (
    <EventTicketsContainer>
      {uniqBy(tickets, 'id')
        .filter(ticket => !ticket.isReservedSeating && ticket.distributionChannel !== 'Upsell')
        .sort((a: ProductSeating, b: ProductSeating) => b.price - a.price)
        .map((ticket: ProductSeating, index) => (
          <EventTicketItem
            key={ticket.id}
            accessCodeType={AccessCodeType.NONE}
            ticket={ticket}
            ticketsLeftInEvent={ticketsLeftInEvent}
            quantity={ticketsToQuantity.get(ticket)}
            updateTicketsToQuantity={updateTicketsToQuantity}
            eventDetailCartEvent={eventDetailCartEvent}
            handleUpdateTicketUpdateAt={handleUpdateTicketUpdateAt}
            financialSchoolState={financialSchoolState}
            isFirstTicket={index === 0}
          />
        ))}
      {uniqBy(accessCodeTickets, 'id').map((ticket: ProductSeating, index) => (
        <EventTicketItem
          key={ticket.id}
          accessCode={prodIdsToAccessCodes.get(parseInt(ticket.id))?.code}
          accessCodeType={
            prodIdsToAccessCodes.has(parseInt(ticket.id)) ? AccessCodeType.UNLOCKED : AccessCodeType.LOCKED
          }
          ticket={ticket}
          quantity={ticketsToQuantity.get(ticket)}
          updateTicketsToQuantity={updateTicketsToQuantity}
          numberInputState={numberInputState}
          eventDetailCartEvent={eventDetailCartEvent}
          handleUpdateTicketUpdateAt={handleUpdateTicketUpdateAt}
          financialSchoolState={financialSchoolState}
          isFirstTicket={index === 0}
        />
      ))}
    </EventTicketsContainer>
  ) : (
    <EventTicketsAccessCodeOnly />
  );
};

export default EventTickets;
