import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  eventFeeInfoContainer: css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  `,
  eventFeeInfoText: css`
    font-family: Rubik-Regular;
    font-size: 14px;
    line-height: 18px;
    color: #767676;
    text-align: center;
    max-height: 100%;
    letter: 0.16px;
  `
};
