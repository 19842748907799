import React, { useEffect, useRef } from 'react';
import { styles } from './EventAccessCodeInput.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { CloseIcon, StyledButton, StyledText } from '@gf/cross-platform-lib/components';
import { Animated, TextInput, TextInputProps, TouchableOpacity, View } from 'react-native';
import { CheckmarkFilled, WarningFilled } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import uniq from 'lodash/uniq';
import { useMediaQuery, useReturnKeyType } from '@gf/cross-platform-lib/hooks';
import { AccessCodeInformation } from '@gf/cross-platform-lib/interfaces';
import { eventDetailsPage, testProperties } from '@gf/cross-platform-lib/utils';

const Container = styled(View)`
  ${styles.container}
`;
const Header = styled(View)`
  ${styles.header}
`;
const HeaderTextWrapper = styled(View)`
  ${styles.headerTextWrapper}
`;
const CodeAppliedContainer = styled((props: any) => <Animated.View {...props} />)`
  ${styles.codeAppliedContainer};
`;
const IconWrapper = styled(View)`
  ${styles.iconWrapper}
`;
const FormContainer = styled(View)`
  ${styles.formContainer}
`;
const InputWrapper = styled(View)`
  ${styles.inputWrapper}
`;
const InputContainer = styled(View)`
  ${styles.inputContainer}
`;
const Input = styled((props: TextInputProps) => <TextInput {...props} />)`
  ${styles.input}
`;
const WarningIconWrapper = styled(View)`
  ${styles.warningIconWrapper}
`;
const ErrorWrapper = styled(View)`
  ${styles.errorWrapper}
`;
const ApplyWrapper = styled(View)`
  ${styles.applyWrapper}
`;
const AppliedAccessCodesContainer = styled(View)`
  ${styles.appliedAccessCodesContainer}
`;
const AppliedAccessCodesHeader = styled(View)`
  ${styles.appliedAccessCodesHeader}
`;
const AppliedAccessCodesWrapper = styled(View)`
  ${styles.appliedAccessCodesWrapper}
`;

interface EventAccessCodeInputProps {
  prodIdsToAccessCodes: Map<number, AccessCodeInformation>;
  promoError: string;
  setPromoCode: Function;
  promoCode: string;
  createPromo: Function;
  removePromo: Function;
  showApplied: boolean;
  setShowApplied: Function;
}

export const EventAccessCodeInput = ({
  prodIdsToAccessCodes,
  promoError,
  setPromoCode,
  promoCode,
  createPromo,
  removePromo,
  showApplied,
  setShowApplied
}: EventAccessCodeInputProps) => {
  const returnKeyType = useReturnKeyType();
  const hasError = promoError !== '';
  const promoCodeInputChange = (input: string) => setPromoCode(input);

  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (showApplied === true) {
      setTimeout(() => {
        setShowApplied(false);
      }, 1500);
    }
  }, [showApplied]);

  const { isDesktop } = useMediaQuery();

  return (
    <Container>
      <Header>
        <HeaderTextWrapper>
          <StyledText typeToken='body02'>Have an access code?</StyledText>
        </HeaderTextWrapper>
        {showApplied === true ? (
          <CodeAppliedContainer style={{ opacity: 1 }} fadeAnim={fadeAnim}>
            <IconWrapper>
              <CheckmarkFilled height={16} width={16} fill={'green'} />
            </IconWrapper>
            <StyledText typeToken='label02'>Code applied!</StyledText>
          </CodeAppliedContainer>
        ) : null}
      </Header>
      <FormContainer isDesktop={isDesktop}>
        <InputContainer isDesktop={isDesktop}>
          <InputWrapper isDesktop={isDesktop}>
            <Input
              isDesktop={isDesktop}
              hasError={hasError}
              placeholder='Type in access code'
              placeholderTextColor={'gray'}
              onChangeText={promoCodeInputChange}
              value={promoCode}
              returnKeyType={returnKeyType}
              aria-label='Type in access code'
              {...testProperties(eventDetailsPage.accessCode)}
            />
            {hasError && (
              <WarningIconWrapper>
                <WarningFilled height={16} width={16} color='#da1e28' />
              </WarningIconWrapper>
            )}
          </InputWrapper>
          {hasError && (
            <ErrorWrapper>
              <StyledText typeToken='helperText01' color='#da1e28'>
                {promoError}
              </StyledText>
            </ErrorWrapper>
          )}
        </InputContainer>
        <ApplyWrapper isDesktop={isDesktop}>
          <StyledButton
            onPress={() => {
              createPromo();
            }}
            buttonType='tertiary'
            disabled={promoCode === ''}
            title={'Apply'}
            accessibilityLabel={'Apply'}
          />
        </ApplyWrapper>
      </FormContainer>
      {prodIdsToAccessCodes.size > 0 ? (
        <AppliedAccessCodesContainer>
          <AppliedAccessCodesHeader>
            <StyledText typeToken='body01'>Access codes applied</StyledText>
          </AppliedAccessCodesHeader>
          <CodeAppliedContainer>
            {uniq(Array.from(prodIdsToAccessCodes.values()).map(accessCodeInfo => accessCodeInfo.code)).map(code => (
              <AppliedAccessCodesWrapper key={code}>
                <StyledText typeToken='label01'>{code}</StyledText>
                <TouchableOpacity
                  onPress={async () => {
                    await removePromo(code);
                  }}
                >
                  <CloseIcon height={16} width={16} fillOpacity={0.6} />
                </TouchableOpacity>
              </AppliedAccessCodesWrapper>
            ))}
          </CodeAppliedContainer>
        </AppliedAccessCodesContainer>
      ) : null}
    </Container>
  );
};

export default EventAccessCodeInput;
