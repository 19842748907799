import { useLaunchDarklyContext } from '../providers';
import { useEffect, useRef } from 'react';

/**
 * TODO: should use one context kind for multiple feature flags to prevent unnecessary rerender. Ref: useUpdatePaymentLDContext
 * Ex:
 * updateLaunchDarklyContext('eventDetails', `${eventId}_${schoolId}`, {
 *       eventId,
 *       schoolId
 *     });
 */
export const useGetEventDetailFeatureFlags = ({
  eventId,
  schoolId
}: {
  eventId: string | undefined;
  schoolId: string | undefined;
}) => {
  const { updateLaunchDarklyContext, isReady, features } = useLaunchDarklyContext();
  const schoolContextUpdatedRef = useRef<string | undefined>();
  const eventContextUpdatedRef = useRef<number | undefined>();

  useEffect(() => {
    async function updateContext() {
      if (!isReady) return;
      if (eventId && Number(eventId) !== eventContextUpdatedRef.current) {
        await updateLaunchDarklyContext('event', `${eventId}`, {
          eventId: Number(eventId)
        });
        eventContextUpdatedRef.current = Number(eventId);
      }
      if (schoolId && schoolId !== schoolContextUpdatedRef.current) {
        await updateLaunchDarklyContext('school', `${schoolId}`, {
          schoolId: schoolId
        });
        schoolContextUpdatedRef.current = schoolId;
      }
    }
    updateContext();
  }, [isReady, eventId, schoolId]);
  const { variation: isEventContext } = features['event-context-experiment'];
  const { variation: isTargetedUser } = features['user-multicontext-experiment'];

  return {
    isEventContext,
    isTargetedUser
  } as { isEventContext: boolean; isTargetedUser: boolean };
};
