import React from 'react';

import { Venue } from '@gf/cross-platform-lib/interfaces';
import { StyledText, Carbonicons } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';
import { isEmpty, openMapNavigation } from '@gf/cross-platform-lib/utils';

import { styles } from './VenueLocation.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { RotateIcon } from '../../../my-tickets/components/RotateIcon/RotateIcon';
import { TextTokenType } from '@gf/cross-platform-lib/styled-system';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';

const VenueLocationContainer = styled(View)`
  ${styles.venueLocationContainer}
`;

const VenueLocationDetailsContainer = styled(View)`
  ${styles.venueLocationDetailsContainer}
`;

const AddressContainer = styled(View)`
  ${styles.addressContainer}
`;

const GetDirectionsButton = styled.TouchableOpacity`
  ${styles.getDirectionsButton}
`;

export type VenueLocationProps = {
  venue?: Venue;
};
type EventLocationTextProps = { text: string | null; typeToken: TextTokenType };
const EventLocationText = ({ text, typeToken }: EventLocationTextProps) => {
  return text !== null && text !== '' ? (
    <StyledText color='#323232' typeToken={typeToken} textAlign={'left'} style={{ letterSpacing: 0.16 }}>
      {text}
    </StyledText>
  ) : null;
};

export const VenueLocation = ({ venue }: VenueLocationProps) => {
  const { isMobile, isNativeApp } = useMediaQuery();
  const location = venue?.location ? venue?.location : venue?.locationName ? venue.locationName : '';
  const name = venue?.name ? `${venue?.name} ` : '';
  const streetAddress = venue?.streetAddress ? `${venue?.streetAddress} ` : '';
  const city = venue?.city ? `${venue?.city}, ` : '';
  const state = venue?.state ? `${venue?.state}, ` : '';
  const zip = venue?.zip ? `${venue?.zip}` : '';

  return (
    <VenueLocationContainer>
      <StyledText color='#323232' typeToken={'body02'} textAlign={'left'}>
        Location
      </StyledText>
      <VenueLocationDetailsContainer>
        <AddressContainer isNativeApp={isNativeApp}>
          {!isEmpty(name) ? <EventLocationText text={name} typeToken='heading01' /> : null}
          {!isEmpty(location) ? <EventLocationText text={location} typeToken='heading01' /> : null}
          {!isEmpty(streetAddress) ? <EventLocationText text={streetAddress} typeToken='body01' /> : null}
          {!isEmpty(city) && !isEmpty(state) && !isEmpty(zip) ? (
            <EventLocationText text={`${city}${state}${zip}`} typeToken='body01' />
          ) : null}
        </AddressContainer>
        {!isEmpty(streetAddress) && (
          <GetDirectionsButton
            isMobile={isMobile}
            onPress={() => {
              openMapNavigation(streetAddress, city, state, zip);
            }}
          >
            <StyledText color='#323232' typeToken={'body01'} textAlign={'left'}>
              Get directions
            </StyledText>
            <RotateIcon>
              <Carbonicons name='directionBearRightFilled' color='#000000' size={14} />
            </RotateIcon>
          </GetDirectionsButton>
        )}
      </VenueLocationDetailsContainer>
    </VenueLocationContainer>
  );
};

export default VenueLocation;
