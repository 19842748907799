import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css`
    cursor: pointer;
    border: none;
    position: relative;
    margin-left: 4px;
    display: flex;
    z-index: 33;
  `,
  info: css<{ isMobile: boolean; infoWidth: number }>`
    padding: 16px;
    width: 396px;
    background: rgb(50, 50, 50);
    border-radius: 4px;
    padding-right: 52px;

    ${({ isMobile, infoWidth }) =>
      isMobile &&
      `
      width: ${infoWidth}px;
    `};
  `,
  infoText: css`
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
  `,
  modal: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    left: -80px;
    top: 24px;
    z-index: 5000;
  `,
  arrowTop: css`
    border-style: solid;
    border-left-width: 8px;
    border-right-width: 8px;
    border-bottom-width: 10px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: rgb(50, 50, 50);
    border-top-color: transparent;
    margin-left: 80px;
  `,
  closeIconWrapper: css`
    position: absolute;
    right: 16px;
    top: 22px;
    z-index: 3;
  `
};
