import React from 'react';
import { View } from 'react-native';
import pluralize from 'pluralize';
import { styles } from './ReservedSeating.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { StyledButton, StyledText } from '@gf/cross-platform-lib/components';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { Event } from '@gf/cross-platform-lib/interfaces';
import { useMediaQuery, useNavigate, useReservedSeating } from '@gf/cross-platform-lib/hooks';
import { useCurrencyFormatter } from '@gf/cross-platform-lib/utils/useCurrencyFormatter';

const Container = styled(View)`
  ${styles.container}
`;
const ReservedSeatingWrapper = styled(View)`
  ${styles.reservedSeatingWrapper}
`;
const TextWrapper = styled(View)`
  ${styles.textWrapper}
`;
const LeftSideWrapper = styled(View)`
  ${styles.leftSideWrapper}
`;
const UnavailableWrapper = styled(View)`
  ${styles.unavailableWrapper}
`;
const ButtonWrapper = styled(View)`
  ${styles.buttonWrapper}
`;

interface ReservedSeatingProps {
  event: Event;
  schoolId?: string;
  encodedString?: string;
  enhancedFeeEnabled: boolean;
}

export const ReservedSeating = ({ event, schoolId, encodedString, enhancedFeeEnabled }: ReservedSeatingProps) => {
  const { isMobile } = useMediaQuery();
  const isSeason = 'eventIds' in event ? true : false;
  const navigate = useNavigate();
  const reservedTickets = event.ticketTypes.filter(ticket => ticket.isReservedSeating);

  const calculateTotal = (ticket: { price: number; fee: number }) => {
    return ticket.price + (enhancedFeeEnabled ? ticket.fee : 0);
  };

  const cheapestTicketType = reservedTickets.reduce((cheapestTicket, currentTicket) => {
    const currentTotal = calculateTotal(currentTicket);
    const cheapestTotal = calculateTotal(cheapestTicket);
    return currentTotal < cheapestTotal ? currentTicket : cheapestTicket;
  }, reservedTickets[0]);

  const hasTicketsAvailable =
    event.salesInfo &&
    cheapestTicketType?.id &&
    event.salesInfo.productSalesMap[cheapestTicketType.id].remainingQuantity > 0;

  const { reservedSeatsState } = useReservedSeating(event.id);
  const selectedSeats = reservedSeatsState.selectedSeats || [];
  const formatCurrencyAndDropTrailingZeros = useCurrencyFormatter().formatAndDropTrailingZeros;

  const ReservedSeatingText = () => {
    if (selectedSeats.length > 0) {
      return (
        <>
          <StyledText>
            {selectedSeats.length} {pluralize('seat', selectedSeats.length)}
          </StyledText>
          {selectedSeats.map(seat => {
            return <StyledText key={seat.id}>{seat.id}</StyledText>;
          })}
        </>
      );
    } else if (hasTicketsAvailable) {
      return (
        <>
          <StyledText typeToken={'body02'}>Reserved seating</StyledText>
          <StyledText typeToken={'body01'}>
            Starting at{' '}
            {formatCurrencyAndDropTrailingZeros(
              cheapestTicketType.price + (enhancedFeeEnabled ? cheapestTicketType.fee : 0)
            )}
          </StyledText>
        </>
      );
    } else {
      return <StyledText>Reserved seating</StyledText>;
    }
  };

  const navigateToSeatSelection = () => {
    if (isSeason && encodedString) {
      navigate(
        `${GOFAN_APP_PAGES['season-seat-selection'].getPath(
          event.id
        )}?isSeason=${isSeason}&encodedString=${encodedString}`
      );
    } else if (isSeason && !encodedString) {
      navigate(`${GOFAN_APP_PAGES['season-seat-selection'].getPath(event.id)}?isSeason=${isSeason}`);
    } else if (!isSeason && encodedString) {
      navigate(
        `${GOFAN_APP_PAGES['seat-selection'].getPath(event.id)}?schoolId=${schoolId}&encodedString=${encodedString}`
      );
    } else {
      navigate(`${GOFAN_APP_PAGES['seat-selection'].getPath(event.id)}?schoolId=${schoolId}`);
    }
  };

  return (
    <Container>
      <ReservedSeatingWrapper isMobile={isMobile}>
        <LeftSideWrapper isMobile={isMobile}>
          <TextWrapper isMobile={isMobile}>
            <ReservedSeatingText />
          </TextWrapper>
          {!hasTicketsAvailable && (
            <UnavailableWrapper isMobile={isMobile}>
              <StyledText textAlign={'center'} typeToken={'label02'}>
                Unavailable
              </StyledText>
            </UnavailableWrapper>
          )}
        </LeftSideWrapper>
        <ButtonWrapper isMobile={isMobile}>
          <StyledButton
            title='Select seats'
            accessibilityLabel='Select seats'
            size={'lg'}
            disabled={!hasTicketsAvailable}
            onPress={navigateToSeatSelection}
          />
        </ButtonWrapper>
      </ReservedSeatingWrapper>
    </Container>
  );
};

export default ReservedSeating;
