import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  eventSoltOutContainer: css`
    align-items: center;
    background-color: #ececec;
    border-radius: 4px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    padding: 16px;
  `,
  soldOutTextPill: css`
    background-color: #ffcccc;
    border-radius: 20px;
    padding: 8px;
    margin-left: 24px;
  `,
  titleWrapper: css<{ isMobile?: boolean }>`
    ${({ isMobile }) => (isMobile ? 'width: 72%' : '')};
  `
};
