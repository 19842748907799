import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  eventDetailsContainer: css`
    display: flex;
    margin-top: 16px;
    padding-bottom: 16px;
    width: 100%;
  `
};
