import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  eventAlertContainerInner: css`
    ${baseStyles.eventAlertContainerInner}
  `,

  eventAlertContainerOuter: css`
    ${baseStyles.eventAlertContainerOuter}
  `,

  alertTextView: css`
    ${baseStyles.alertTextView}
  `,

  warningFilledWrapper: css`
    ${baseStyles.warningFilledWrapper}
  `,

  yellowBar: css`
    ${baseStyles.yellowBar}
  `
};
