import React, { useState } from 'react';
import styled from '@gf/cross-platform-lib/styled-components';

import { styles } from './Tooltip.styles';
import { PHONE_IS_TICKET_NOTI } from '@gf/cross-platform-lib/constants';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { Carbonicons } from '@gf/cross-platform-lib/components';
import { TouchableOpacity } from 'react-native';
import { Dimensions } from 'react-native';

const Info = styled.View`
  ${styles.info};
`;

const InfoText = styled.Text`
  ${styles.infoText};
`;

const Modal = styled.View`
  ${styles.modal};
`;

const ArrowTop = styled.View`
  ${styles.arrowTop};
`;

const Wrapper = styled.View`
  ${styles.wrapper};
`;

const CloseIconWrapper = styled.View`
  ${styles.closeIconWrapper};
`;

export const Tooltip = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { isMobile } = useMediaQuery();
  const screenWidth = Dimensions.get('window').width;

  return (
    <Wrapper>
      <TouchableOpacity
        onPress={() => {
          setIsOpenModal(!isOpenModal);
        }}
      >
        <Carbonicons name='information' size={14} />
      </TouchableOpacity>

      {isOpenModal && (
        <Modal>
          <CloseIconWrapper>
            <TouchableOpacity
              onPress={() => {
                setIsOpenModal(false);
              }}
            >
              <Carbonicons name='close' size={20} color='#ffffff' />
            </TouchableOpacity>
          </CloseIconWrapper>
          <ArrowTop />
          <Info isMobile={isMobile} infoWidth={screenWidth - 104}>
            <InfoText>{PHONE_IS_TICKET_NOTI}</InfoText>
          </Info>
        </Modal>
      )}
    </Wrapper>
  );
};
