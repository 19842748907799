import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  lowTicketWarningContainer: css<{ isEvent: boolean; soldOut: boolean }>`
    align-items: center;
    align-self: center;
    border-radius: 8px;
    background-color: ${props => (props.soldOut ? 'rgb(240,128,128)' : 'rgb(255,250,205)')};
    padding: 0px 8px;
  `
};
