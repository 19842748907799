import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  title: css`
    color: rgb(50, 50, 50);
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    word-wrap: break-word;
    max-height: 100%;
    > p {
      line-height: 1.5;
      margin-bottom: 1rem;
    }

    > h1 {
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }
  `
};
