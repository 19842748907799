import React from 'react';
import styled from 'styled-components';
import { styles } from './EventDescription.styles';

export type EventDescriptionProps = {
  description: string;
};

const Title = styled.div`
  ${styles.title}
`;

export const EventDescription = ({ description }: EventDescriptionProps) => {
  const contentClickHandler = (e: any) => {
    const targetLink = e.target.closest('a');
    if (!targetLink) return;
    e.preventDefault();

    if (targetLink.getAttribute('href') && targetLink.getAttribute('href').slice(0, 4) === 'http') {
      window.open(targetLink.href);
    }
  };

  return <Title onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: description }} />;
};

export default EventDescription;
