import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  eventFeeInfoContainer: css`
    ${baseStyles.eventFeeInfoContainer}
  `,
  eventFeeInfoText: css`
    ${baseStyles.eventFeeInfoText}
  `
};
