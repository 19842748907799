import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  container: css`
    align-items: center;
    flex-direction: column;
    margin-bottom: 24px;
    margin-top: 18px;
  `,
  header: css`
    margin-bottom: 8px;
    margin-top: 24px;
  `
};
