import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  container: css`
    flex-direction: row;
    margin-top: 12px;
  `,
  iconWrapper: css`
    margin-right: 4px;
  `
};
