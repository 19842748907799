import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  priceDisplayContainer: css``,
  title: css`
    font-family: 'Roboto-Regular';
    font-size: 16px;
    line-height: 23px;
  `,
  subtitle: css`
    font-family: 'Roboto-Regular';
    font-size: 12px;
    line-height: 20px;
    color: #323232b2;
  `
};
