import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  fundraiserMetricsContainer: css`
    align-items: center;
    flex-direction: row;
    margin-top: 16px;
  `,
  infoContainer: css`
    align-items: center;
    flex-direction: row;
  `,
  verticalDivider: css`
    background-color: rgb(211, 211, 211);
    height: 22px;
    margin-horizontal: 15px;
    width: 2px;
  `,
  metricsText: css`
    color: rgb(82, 82, 82);
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.16px;
    margin-left: 10px;
  `
};
