import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  eventUnavailableContainer: css`
    align-items: center;
    padding-top: 16px;
    margin-botton: 8px;
  `,
  eventUnavailableTextWrapper: css`
    flex-direction: row;
    flex-shrink: 1;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 8px;
  `,
  soldOut: css`
    color: rgb(50, 50, 50);
    font-family: Rubik-Regular;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.32px;
    line-height: 16px;
    text-align: center;
  `
};
