import React from 'react';

import { StyledText } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';
import { styles } from './EventSoldOut.style';
import styled from '@gf/cross-platform-lib/styled-components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';

const EventSoltOutContainer = styled(View)`
  ${styles.eventSoltOutContainer}
`;
const SoldOutTextPill = styled(View)`
  ${styles.soldOutTextPill}
`;

const TitleWrapper = styled(View)`
  ${styles.titleWrapper}
`;

export const EventSoldOut = () => {
  const { isMobile } = useMediaQuery();

  return (
    <EventSoltOutContainer>
      <TitleWrapper isMobile={isMobile}>
        <StyledText typeToken='heading01'>We&apos;re sorry tickets for this event are sold out.</StyledText>
      </TitleWrapper>
      <SoldOutTextPill>
        <StyledText typeToken='label01'>Sold out</StyledText>
      </SoldOutTextPill>
    </EventSoltOutContainer>
  );
};

export default EventSoldOut;
