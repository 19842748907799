import React, { useEffect, useRef, useState } from 'react';

import {
  FundraiserProgressBar,
  FundraiserDescriptionContainer,
  ReservedSeating,
  ReservedSeatingTicket,
  EventTickets,
  EventFeeInfo,
  EventUnavailable,
  EventAlert,
  EventTooltip,
  EventDetails,
  EventSelectOptions,
  EventHeader,
  VenueLocation,
  EventDetailSkeleton
} from '../../components';
import { Cart, TicketsAdditionalInfo } from '@gf/cross-platform-lib/models';
import {
  Event,
  EventSeason,
  InformationForm,
  ProductSeating,
  ProductType,
  Promotion,
  AccessCodeInformation,
  InformationFormField,
  IEventSponsorship
} from '@gf/cross-platform-lib/interfaces';
import {
  useEventInfo,
  useMediaQuery,
  useNavigate,
  useParams,
  useReservedSeating,
  useTracking,
  useRouter,
  isSaleTimeTicket,
  useCartState,
  useCartLimit,
  isTaxEnable
} from '@gf/cross-platform-lib/hooks';
import {
  useModal,
  MODAL_TYPES,
  usePullRefresh,
  useScrollView,
  useAccessCodeProvider,
  TaxFlagRules,
  useLaunchDarklyContext
} from '@gf/cross-platform-lib/providers';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import {
  getDaysLeft,
  isEmpty,
  TicketTypeSelectionMap,
  eventDetailsPage,
  testProperties,
  isStateUsingEnhancedFee
} from '@gf/cross-platform-lib/utils';
import { StyledButton, StyledText } from '@gf/cross-platform-lib/components';
import { View, Platform, Text } from 'react-native';
import {
  EventAvailabilityMessage,
  getAvailableTickets,
  useEventAvailabilityMessage,
  getEventMinimumTickets,
  toTicketTypeSelections,
  ticketTypeMatchesSelectedSeat
} from '../../util';
import { InputChangeType } from '@gf/cross-platform-lib/components/NumberInput/contants';
import { styles } from './EventDetailsView.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { EventAccessCodeInput } from '../../components/EventAccessCode/EventAccessCodeInput';
import {
  createPromotionLock,
  deletePromoLock,
  updatePromoLock,
  useFetchSchool
} from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { getEventDetailsPageViewProps } from '@gf/cross-platform-lib/hooks/tracking/utils';
import { SeatSelectionHoldExpired } from '../../../seat-selection/components';
import { getEventType } from '@gf/cross-platform-lib/hooks/tracking/useCustomTracking';
import { createPromo, handleTicketLimits, removePromo } from '../../components/EventAccessCode/accessCodeHelper';
import { EventShareWidget } from '../../components/EventShareWidget';
import { useDonationsRaised } from '@gf/cross-platform-lib/hooks/useDonationsRaised';
import { getPackCountQuantity } from '../../../review-and-buy/hooks';

import ExampleEventContextualLDComponent from './ExampleEventContextualLDComponent';

const EventDetailsContainer = styled(View)`
  ${styles.eventDetailsContainer}
`;
const GetTicketsButton = styled(View)`
  ${styles.getTicketsButton}
`;
const EventDetailsWrapper = styled(View)`
  ${styles.eventDetailsWrapper}
`;
const SkeletonWrapper = styled(View)`
  ${styles.skeletonWrapper}
`;

interface EventDetailsViewProps {
  eventDetail: Event | EventSeason;
  schoolId?: string;
  isSeason: boolean;
  ticketEncodedStrings?: string[];
  encodedString?: string;
  isFundraiser?: boolean;
  isDonationOngoing?: boolean;
  isMobilePass?: boolean;
  isFetchingEvent: boolean;
  pullDownLoading: boolean;
  refetchEvent: () => void;
  setPullDownLoading: (value: boolean) => void;
  sponsor?: IEventSponsorship;
  isEventContext: boolean;
  isTargetedUser: boolean;
}

let ticketTypesWithInputType: { [x: string]: InputChangeType | undefined };
let ticketsByUpdateAt: { [k: string]: number } = {};

const getNewFormFields = (formFields: InformationFormField[]) => {
  const newFormFields = formFields.map(item => {
    if (item.fieldType === 'DROPDOWN') {
      const isEmptyOptions = item.options?.every(option => isEmpty(option));
      return isEmptyOptions ? { ...item, required: false } : item;
    }
    return item;
  });
  return newFormFields;
};

export const EventDetailsView = ({
  eventDetail,
  schoolId,
  isSeason = false,
  ticketEncodedStrings = [],
  encodedString,
  isFundraiser = false,
  isFetchingEvent,
  refetchEvent,
  setPullDownLoading,
  pullDownLoading,
  sponsor,
  isEventContext,
  isTargetedUser
}: EventDetailsViewProps) => {
  const launchDarklyContext = useLaunchDarklyContext();
  const taxFlagRules = launchDarklyContext.features['tax-enabled']?.variation as unknown as TaxFlagRules;

  // Event-Related Variables
  const ticketTypes = getAvailableTickets(eventDetail.ticketTypes, schoolId, ticketEncodedStrings);

  const event: Event = {
    ...eventDetail,
    form:
      !isEmpty(eventDetail.form) && !isEmpty(eventDetail.form?.formFields)
        ? { ...eventDetail.form!, formFields: getNewFormFields(eventDetail.form?.formFields!) }
        : eventDetail.form,
    ticketTypes: ticketTypes.map(item => ({
      ...item,
      form:
        !isEmpty(item.form) && !isEmpty(item.form?.formFields)
          ? { ...item.form!, formFields: getNewFormFields(item.form?.formFields!) }
          : item.form,
      productSales: !isEmpty(eventDetail?.salesInfo?.productSalesMap)
        ? eventDetail?.salesInfo?.productSalesMap[item.id]
        : undefined
    }))
  };
  const financialSchoolID = event?.financialSchoolID ? event.financialSchoolID : '';
  const financialSchoolState = event?.financialSchoolState ?? '';
  const message = useEventAvailabilityMessage(event, schoolId);
  const seasonHasEvents = !isEmpty(event.eventIds);

  // Navigation and UI-related variables
  const params = useParams();
  const { scrollTo, scrollToTop } = useScrollView();

  useEffect(() => {
    scrollToTop({ behavior: 'auto' });
  }, []);

  const eventVenueDetailsRef = useRef<View>(null);
  const navigate = useNavigate();
  const { showModal, hideModal } = useModal();
  const { isMobile } = useMediaQuery();
  const { data, isFetching: loading, refetch } = useFetchSchool(schoolId || event?.schoolHuddleId || '');
  const school = data?.data;
  if (school) school.primaryColor = school?.primaryColor ?? '000000';
  const eventInfo = useEventInfo(event);
  const hasReservedSeating = event.ticketTypes.some(
    ticketTypes =>
      ticketTypes.isReservedSeating &&
      isSaleTimeTicket(ticketTypes.salesStartDateTime, ticketTypes.salesEndDateTime, ticketTypes.timezone)
  );
  const { reservedSeatsState, clearSeats } = useReservedSeating(event.id);
  const seatsSelected = reservedSeatsState.selectedSeats ? reservedSeatsState.selectedSeats.length : 0;
  const { addRemoveAccessCodeTask, clearTask, clearAllTasks } = useAccessCodeProvider();
  const { cartSchools } = useCartState();
  const { limitValidation } = useCartLimit();

  const [isHoldExpired, setIsHoldExpired] = useState<boolean>(false);

  const handleTimerExpires = () => {
    setIsHoldExpired(true);
    clearSeats();
  };

  // Cart, Validation and Ticket state setup
  const cart = Cart.getInstance();

  const processTicketState = (currentTicketState: Map<ProductSeating, number> | null): Map<ProductSeating, number> => {
    const updatedTicketState = new Map();

    const currentTickets: {
      [id: string]: { ticketType: ProductSeating; quantity: number };
    } = {};
    currentTicketState?.forEach((quantity, ticketType) => {
      if (ticketType) {
        currentTickets[ticketType.id] = { ticketType, quantity };
      }
    });

    event.ticketTypes.forEach((eventTicketType: ProductSeating) => {
      const currentTicketType = currentTickets[eventTicketType.id]?.ticketType || eventTicketType;
      const initialQuantity =
        currentTickets[eventTicketType.id]?.quantity || cart.getTicket(eventTicketType.id)?.quantity || 0;

      let selectedSeatsCount = 0;
      if (eventTicketType.isReservedSeating && reservedSeatsState.selectedSeats?.length) {
        selectedSeatsCount = reservedSeatsState.selectedSeats.filter(selectedSeat =>
          ticketTypeMatchesSelectedSeat(eventTicketType, selectedSeat)
        ).length;
      }

      const newQuantity = eventTicketType.isReservedSeating ? selectedSeatsCount : initialQuantity;
      updatedTicketState.set(currentTicketType, newQuantity);
    });

    return updatedTicketState;
  };

  let tempTicketsToQuantity: Map<ProductSeating, number> = processTicketState(null);

  const calculateInitialTicketState = (): Map<ProductSeating, number> => {
    return processTicketState(null);
  };

  const updateTicketState = (currentTicketState: Map<ProductSeating, number>): Map<ProductSeating, number> => {
    return processTicketState(currentTicketState);
  };

  useEffect(() => {
    const newTicketState = updateTicketState(ticketsToQuantity);
    setTicketsToQuantity(newTicketState);
  }, [reservedSeatsState.selectedSeats]);

  // State-Related Variables
  const [showAlert, setShowAlert] = useState<boolean>(!isEmpty(event.alert));
  const [showPaymentError, setShowPaymentError] = useState<boolean>(() => {
    const tempParams = params as { paymentError: string };
    return tempParams.paymentError === 'true' ? true : false;
  });
  const [ticketsToQuantity, setTicketsToQuantity] = useState<Map<ProductSeating, number>>(
    calculateInitialTicketState()
  );
  const [prodIdsToAccessCodes, setProdIdsToAccessCodes] = useState<Map<number, AccessCodeInformation>>(
    new Map<number, AccessCodeInformation>()
  );
  const [numberInputState, setNumberInputState] = useState<boolean>(true);
  const [accessCodes, setAccessCodes] = useState<Promotion[]>([]);

  // Tracking and additional ticket info
  const {
    trackProductRemoved,
    trackProductAdded,
    trackPromoteCodeEntered,
    trackPromoteCodeApplied,
    trackPromoteCodeDenied,
    trackPromoteCodeRemoved
  } = useTracking();
  const ticketsAdditionalInfo = TicketsAdditionalInfo.getInstance();
  const { trackEventDetailsPageView } = useTracking();

  // Access codes and seating-related variables
  const allRequireAccessCode = event.ticketTypes.filter(ticket => ticket.isPromotionRequired !== true).length === 0;

  useEffect(() => {
    const newTicketState = processTicketState(ticketsToQuantity);
    setTicketsToQuantity(newTicketState);
  }, [eventDetail]);

  useEffect(() => {
    const newTicketState = processTicketState(null);
    setTicketsToQuantity(newTicketState);
  }, [cartSchools]);

  const addToCart = async () => {
    const tickets = toTicketTypeSelections(ticketsToQuantity, prodIdsToAccessCodes, encodedString);
    const selectedSeats = reservedSeatsState.selectedSeats;
    if (school) {
      if (cart.isExpired() || cart.isEmpty()) {
        await ticketsAdditionalInfo.clearTicketsForms();
        await ticketsAdditionalInfo.addEventsForm(event);
      }
      if (!isEmpty(ticketTypesWithInputType) && !isEmpty(tickets)) {
        Array.from(ticketsToQuantity).forEach(([ticket, quantity]) => {
          trackQuantityChange(ticket, quantity, ticketTypesWithInputType[ticket.id]);
        });
      }
      isSeason
        ? await cart.addSeasonTicket({
            school,
            season: eventDetail as EventSeason,
            tickets,
            selectedSeats
          })
        : await cart.addTicket({
            school,
            event,
            eventInfo,
            tickets,
            selectedSeats
          });
      // cart.addTicket ignored ticket with quantity equal 0 to add. so:
      const removingTickets = tickets.filter(ticket => ticket.quantity === 0);
      removingTickets.forEach(async ticket => await cart.removeTicket(ticket.ticketType.id));
      await ticketsAdditionalInfo.addAdditionalFormsFromTickets(tickets);
      navigate(
        `${GOFAN_APP_PAGES.reviewAndBuy.path}?${isSeason ? `seasonId=${event.id}` : `eventId=${event.id}`}${
          encodedString ? `&p=${encodedString}` : ''
        }`
      );
    }
  };

  const router = useRouter();

  const multiContextIndicator = () => <Text>Multi-Context: Success</Text>;
  // TODO-usercontext: Remove traces of this code when user context QA'd and greenlit

  const changeTicketQuantity = (ticket: ProductSeating, newQuantity: number) =>
    setTicketsToQuantity((prevState: Map<ProductSeating, number>) => {
      const newTicketsToQuantity: Map<ProductSeating, number> = new Map(prevState);
      newTicketsToQuantity.set(ticket, newQuantity);
      tempTicketsToQuantity = newTicketsToQuantity;
      return newTicketsToQuantity;
    });

  const getAccessCodeTicketIdByCodeName = () => {
    const groupedCode: { [k: string]: [number] } = {};

    for (const [key, value] of Array.from(prodIdsToAccessCodes)) {
      if (!groupedCode[value.code]) {
        groupedCode[value.code] = [key];
      } else {
        groupedCode[value.code].push(key);
      }
    }
    return groupedCode;
  };

  const removeQuantityWithAccessCode = (code = '', showModal?: Function) => {
    const ids = getAccessCodeTicketIdByCodeName();
    const ticketsPromo = Cart.getInstance().getTicketCartsWithAccessCode();
    const haveTicketsWithExpiredCode = ticketsPromo.some(ticket => code === ticket.accessCode);
    if (isEmpty(ids) || getTotalTicketsQuantity(tempTicketsToQuantity) === 0 || haveTicketsWithExpiredCode) {
      return;
    }
    ids[code].forEach(ticketId => {
      const found = Array.from(ticketsToQuantity.keys()).find(
        ticket => `${ticket.id}` === `${ticketId}`
      ) as ProductSeating;
      const codeInfo = prodIdsToAccessCodes.get(ticketId);
      setTicketsToQuantity((prevState: Map<ProductSeating, number>) => {
        const newTicketsToQuantity: Map<ProductSeating, number> = new Map(prevState);
        newTicketsToQuantity.set(found, 0);
        return newTicketsToQuantity;
      });
      removePromotion(codeInfo?.code || '');
      if (typeof showModal === 'function') {
        showModal();
      }
    });
  };

  const removePaymentError = () => {
    setShowPaymentError(false);
    router.updateCurrentUrl(encodedString ? `${encodedString}` : `${event.id}`, schoolId ? { schoolId } : {});
  };

  const scrollToEventDetails = () =>
    eventVenueDetailsRef.current?.measure(
      (x: number, y: number, width: number, height: number, pageX: number, pageY: number) => {
        scrollTo({ y: pageY });
      }
    );

  const handleUpdateTicketUpdateAt = (ticketId: string) => {
    ticketsByUpdateAt = { ...ticketsByUpdateAt, [ticketId]: Date.now() };
  };

  const getTicketsButtonEnabled = () =>
    Array.from(ticketsToQuantity.values()).reduce((partialSum, quantity) => partialSum + quantity, 0) > 0;

  const getTotalTicketsQuantity = (tickets: Map<ProductSeating, number>) =>
    Array.from(tickets.values()).reduce((partialSum, quantity) => partialSum + quantity, 0);

  const trackQuantityChange = (ticket: ProductSeating, quantity: number, changeType?: InputChangeType) => {
    const prevQuantity = cart.getTicket(ticket.id).quantity || 0;
    if (isEmpty(ticket)) {
      return;
    }
    if (prevQuantity > quantity) {
      trackProductRemoved({
        renewal: false,
        uniqueUrl: encodedString || '',
        ticket,
        event,
        quantity,
        index: event.ticketTypes.findIndex(tk => tk.id === ticket.id),
        removeAll: false,
        inputMethod: changeType === InputChangeType.ON_DECREMENT ? 'Click' : 'enter',
        coupon: '',
        inCart: false
      });
    }

    if (prevQuantity < quantity) {
      trackProductAdded({
        renewal: false,
        uniqueUrl: encodedString || '',
        ticket,
        event: {
          ...event,
          eventType: getEventType({
            activity: event.activity,
            isMobilePass: ticket.productType === ProductType.MOBILEPASS
          })
        },
        quantity,
        index: event.ticketTypes.findIndex(tk => tk.id === ticket.id),
        inputMethod: changeType === InputChangeType.ON_INCREMENT ? 'Click' : 'enter',
        coupon: '',
        inCart: false,
        opponentSchoolId: event.opponentSchoolId || '',
        formFieldNames: !isEmpty(event.form)
          ? (event?.form as unknown as InformationForm)?.formFields.map(({ name }) => name).join()
          : ''
      });
    }
  };

  const updateTicketsToQuantity = async (ticket: ProductSeating, newQuantity: number, changeType?: InputChangeType) => {
    let tempProdIdsToAccessCodes: Map<number, AccessCodeInformation> = new Map<number, AccessCodeInformation>();
    const packCount = ticket?.packCount || 0;
    const qtyWithPack = packCount > 1 ? packCount * newQuantity : newQuantity;
    if (prodIdsToAccessCodes.has(parseInt(ticket.id))) {
      event.ticketTypes.forEach(ticketType => {
        if (ticketType.id === ticket.id) {
          ticketType.limit = ticket.limit;
        }
      });

      setNumberInputState(false);
      changeTicketQuantity(ticket, newQuantity);
      const newTicketsToQuantity: Map<ProductSeating, number> = new Map(ticketsToQuantity);
      newTicketsToQuantity.set(ticket, newQuantity);
      tempTicketsToQuantity = newTicketsToQuantity;
      const codeInfo = prodIdsToAccessCodes.get(parseInt(ticket.id))!;
      // If we have existing promo in the cart, re-apply the promo id
      if (cart.getTicket(ticket.id).promos && cart.getTicket(ticket.id).promos!.length > 0) {
        codeInfo.id = cart.getTicket(ticket.id).promos![0];
      }
      if (
        (!codeInfo.id && !cart.getTicket(ticket.id).promos) ||
        (cart.getTicket(ticket.id).promos && !(cart.getTicket(ticket.id).promos!.length > 0))
      ) {
        // If it does not have an id, it's new and we must create a promo lock, set the id
        const promoResponse = await createPromotionLock(parseInt(event.id), codeInfo.code, qtyWithPack, isSeason);
        if ('statusCode' in promoResponse && promoResponse.statusCode.toString() === '409') {
          showModal(MODAL_TYPES.ACCESS_CODE_MODAL, {
            title: 'Access Code Error',
            description: 'This access code has reached its maximum number of uses.',
            remove: () => {
              setNumberInputState(true);
              if (!getTicketsButtonEnabled()) {
                return removePromotion(codeInfo.code!);
              }
              changeTicketQuantity(ticket, 0);
            }
          });
          return;
        }
        if ('id' in promoResponse) {
          addRemoveAccessCodeTask(removeQuantityWithAccessCode, event.id, codeInfo.code || '', Date.now());
          setProdIdsToAccessCodes(prevState => {
            const tempMap = new Map(prevState);
            tempMap.set(parseInt(ticket.id), {
              code: codeInfo.code,
              id: promoResponse.id,
              createAt: Date.now()
            });
            tempProdIdsToAccessCodes = tempMap;
            return tempMap;
          });
        }
        if ('quantityLocked' in promoResponse && promoResponse.quantityLocked < qtyWithPack) {
          showModal(MODAL_TYPES.TICKET_LIMITS_MODAL, {
            title: 'ORDER_LIMIT',
            ticketsLeft: promoResponse.quantityLocked,
            isPromotionRequired: true,
            packCount: packCount > 0,
            onConfirm: async () => {
              changeTicketQuantity(ticket, getPackCountQuantity(promoResponse.quantityLocked, ticket?.packCount));
              hideModal();
            }
          });
        }
      } else {
        // check if cart already has promo items
        if (cart.getTicket(ticket.id).promos) {
          setProdIdsToAccessCodes(prevState => {
            const tempMap = new Map(prevState);
            tempMap.set(parseInt(ticket.id), {
              code: codeInfo.code,
              id: cart.getTicket(ticket.id).promos![0]
            });
            tempProdIdsToAccessCodes = tempMap;
            return prevState;
          });
        }
        // If it already had an id, we must update the lock
        if (newQuantity === 0 && codeInfo.id) {
          // If quantity is 0, delete the lock, remove id
          if (getTotalTicketsQuantity(tempTicketsToQuantity) === 0) {
            clearTask(codeInfo.code);
          }
          deletePromoLock(codeInfo.id);
          setProdIdsToAccessCodes(prevState => {
            const tempMap = new Map(prevState);
            tempMap.set(parseInt(ticket.id), { code: codeInfo.code });
            tempProdIdsToAccessCodes = tempMap;
            return tempMap;
          });
        } else if (codeInfo.id) {
          // Update quantity
          const promoResponse = await updatePromoLock(codeInfo.id, qtyWithPack);
          if ('statusCode' in promoResponse && promoResponse.statusCode.toString() === '409') {
            showModal(MODAL_TYPES.ACCESS_CODE_MODAL, {
              title: 'Access Code Error',
              description: 'This access code has reached its maximum number of uses.',
              remove: () => {
                setNumberInputState(true);
                if (!getTicketsButtonEnabled()) {
                  return removePromotion(codeInfo.code!);
                }
                changeTicketQuantity(ticket, 0);
              }
            });
            return;
          }
          // Expired? create new
          if (
            'message' in promoResponse &&
            promoResponse.message.includes('Promo Code Lock is not found for request')
          ) {
            const promoResponse = await createPromotionLock(parseInt(event.id), codeInfo.code, qtyWithPack, isSeason);
            if ('id' in promoResponse) {
              addRemoveAccessCodeTask(removeQuantityWithAccessCode, event.id, codeInfo.code || '', Date.now());
              setProdIdsToAccessCodes(prevState => {
                const tempMap = new Map(prevState);
                tempMap.set(parseInt(ticket.id), {
                  code: codeInfo.code,
                  id: promoResponse.id,
                  createAt: Date.now()
                });
                tempProdIdsToAccessCodes = tempMap;
                return tempMap;
              });
            }
          }
        }
      }
      setNumberInputState(true);
    }
    ticketTypesWithInputType = { ...ticketTypesWithInputType, [ticket.id]: changeType };
    setTicketsToQuantity((prevState: Map<ProductSeating, number>) => {
      const newTicketsToQuantity: Map<ProductSeating, number> = new Map(prevState);
      newTicketsToQuantity.set(ticket, newQuantity);
      tempTicketsToQuantity = newTicketsToQuantity;
      return newTicketsToQuantity;
    });

    const ticketMap: TicketTypeSelectionMap = {};

    Array.from(ticketsToQuantity.keys()).forEach(key => {
      ticketMap[key.id.toString()] = ticketsToQuantity.get(key)!;
    });
    ticketMap[ticket.id.toString()] = qtyWithPack;
    if (!school) {
      return null;
    }
    const { eventToAdd: eventDetailCartEvent } = Cart.getInstance().convertData(
      school,
      event,
      eventInfo,
      toTicketTypeSelections(tempTicketsToQuantity, prodIdsToAccessCodes, encodedString, ticketsByUpdateAt),
      reservedSeatsState.selectedSeats
    );
    const codeInfo = prodIdsToAccessCodes.get(parseInt(ticket.id))?.id
      ? prodIdsToAccessCodes.get(parseInt(ticket.id))
      : tempProdIdsToAccessCodes.get(parseInt(ticket.id));

    const {
      type,
      adjustedQuantity = 0,
      isPromotionRequired,
      onConfirm,
      isOutOfStock
    } = await handleTicketLimits(
      ticket,
      newQuantity,
      codeInfo?.id || '',
      isSeason,
      eventDetailCartEvent,
      packCount,
      changeTicketQuantity,
      limitValidation
    );

    if (adjustedQuantity > 0 || isOutOfStock) {
      showModal(MODAL_TYPES.TICKET_LIMITS_MODAL, {
        type,
        ticketsLeft: adjustedQuantity,
        isPromotionRequired,
        packCount: packCount > 0,
        onConfirm: () => {
          onConfirm();
          hideModal();
        },
        hideModalWhenClickOutSite: false
      });
    }
  };

  useEffect(() => {
    const addEventsForm = async () => {
      if (!isEmpty(event)) {
        await ticketsAdditionalInfo.addEventsForm(event, isSeason);
      }
    };
    addEventsForm();
  }, [event]);

  useEffect(() => {
    if (event?.id && event?.activity?.name && event?.schoolHuddleId) {
      trackEventDetailsPageView(
        getEventDetailsPageViewProps(event.id, event?.activity?.name, isSeason, financialSchoolID, event.schoolHuddleId)
      );
    }
  }, [event?.id, event?.activity?.name, event?.schoolHuddleId]);

  const { refreshing: isPullRefreshing, setRefreshing } = usePullRefresh();
  useEffect(() => {
    if (isPullRefreshing && !pullDownLoading) {
      setPullDownLoading(true);
      refetch();
      refetchEvent();
    }
  }, [isPullRefreshing, loading, isFetchingEvent, pullDownLoading]);
  useEffect(() => {
    if (isPullRefreshing && !loading && !isFetchingEvent && pullDownLoading) {
      setRefreshing(false);
      setPullDownLoading(false);
    }
  }, [isPullRefreshing, loading, isFetchingEvent, pullDownLoading]);
  useEffect(() => () => clearAllTasks(), []);

  // Promo Code Stuff
  const [promoCode, setPromoCode] = useState<string>('');
  const [promoError, setPromoError] = useState<string>('');
  const [promoId, setPromoCodeId] = useState<string>('');
  const [showApplied, setShowApplied] = useState<boolean>(false);
  const createPromotion = () => {
    createPromo(
      trackPromoteCodeEntered,
      trackPromoteCodeDenied,
      trackPromoteCodeApplied,
      promoCode,
      setPromoCode,
      setPromoCodeId,
      setPromoError,
      event.id,
      ticketsToQuantity,
      prodIdsToAccessCodes,
      setProdIdsToAccessCodes,
      setTicketsToQuantity,
      setAccessCodes
    );
  };
  const removePromotion = (code: string) => {
    clearTask(code);
    removePromo(
      code,
      prodIdsToAccessCodes,
      ticketsToQuantity,
      setProdIdsToAccessCodes,
      trackPromoteCodeRemoved,
      promoId,
      setTicketsToQuantity,
      setPromoCode,
      setPromoError
    );
  };

  const { donationsRaisedTotal } = useDonationsRaised(eventDetail);
  const isEventDonationOngoing: boolean = event.ticketTypes[0]?.productType === ProductType.DONATION_ONGOING;

  if (pullDownLoading) {
    return (
      <SkeletonWrapper>
        <EventDetailSkeleton isMobile={isMobile} isShowLayout={false} />
      </SkeletonWrapper>
    );
  }

  return (
    <EventDetailsContainer isWeb={Platform.OS === 'web'}>
      {school ? (
        <EventDetailsWrapper>
          {showPaymentError ? (
            <EventAlert
              alertType='Payment Error'
              alert={`We're sorry, there was a problem with processing your order. Please try again.`}
              setShowAlert={removePaymentError}
            />
          ) : null}
          {showAlert && event.alert ? <EventAlert alert={event.alert} setShowAlert={setShowAlert} /> : null}
          <EventHeader event={event} school={school} sponsor={sponsor} isFundraiser={isFundraiser} />
          {message !== EventAvailabilityMessage.AVAILABLE ? (
            <View style={{ width: '100%', position: 'relative' }}>
              {!isEventDonationOngoing && <EventTooltip />}
              <EventUnavailable message={message} schoolHuddleId={school.huddleId} schoolName={school.name} />
            </View>
          ) : (
            <View style={{ width: '100%', position: 'relative' }}>
              {hasReservedSeating && !isFundraiser && event.description && (
                <EventSelectOptions scrollToEventDetails={scrollToEventDetails} />
              )}
              {isFundraiser && (
                <View {...testProperties('fundraiser-container')}>
                  <View
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                    {...testProperties('fundraiser-header')}
                  >
                    {event.goal ? (
                      <View>
                        <StyledText typeToken='heading05' {...testProperties('raised-amount')}>
                          ${donationsRaisedTotal.toLocaleString()}
                        </StyledText>
                        <StyledText typeToken='bodyCompact02' {...testProperties('fundraiser-goal')}>
                          of ${parseInt(event.goal).toLocaleString()} raised!
                        </StyledText>
                      </View>
                    ) : (
                      <View></View>
                    )}
                    <EventShareWidget event={event} schoolName={school.name} />
                  </View>
                  {event.goal && (
                    <FundraiserProgressBar
                      donationGoal={Number(event.goal)}
                      donationsRaised={donationsRaisedTotal}
                      barColor={school.primaryColor}
                      isMobile={isMobile}
                      isEventDetails={true}
                    />
                  )}
                </View>
              )}
              {isFundraiser && (
                <FundraiserDescriptionContainer
                  description={event.description}
                  donationsMade={event?.salesInfo?.ticketsSold ?? 0}
                  daysLeft={getDaysLeft(event.startDateTime, event.endDateTime, event.timeZone)}
                />
              )}
              {!isEventDonationOngoing && <EventTooltip />}
              {hasReservedSeating && isHoldExpired ? <SeatSelectionHoldExpired isMobile={isMobile} /> : null}
              {hasReservedSeating && seatsSelected ? (
                <ReservedSeatingTicket
                  event={event}
                  isSeason={isSeason}
                  onTimerExpire={handleTimerExpires}
                  expiresAt={reservedSeatsState?.holdToken?.expiresAt || ''}
                  schoolId={schoolId}
                  encodedString={encodedString}
                />
              ) : hasReservedSeating ? (
                <ReservedSeating
                  event={event}
                  schoolId={schoolId}
                  encodedString={encodedString}
                  enhancedFeeEnabled={isStateUsingEnhancedFee(financialSchoolState)}
                />
              ) : null}
              <EventTickets
                financialSchoolState={financialSchoolState}
                eventTicketLimit={getEventMinimumTickets(event)}
                prodIdsToAccessCodes={prodIdsToAccessCodes}
                productSalesMap={event?.salesInfo?.productSalesMap}
                updateTicketsToQuantity={updateTicketsToQuantity}
                ticketsToQuantity={ticketsToQuantity}
                numberInputState={numberInputState}
                accessCodes={accessCodes}
                eventDetailCartEvent={
                  Cart.getInstance().convertData(
                    school,
                    event,
                    eventInfo,
                    toTicketTypeSelections(ticketsToQuantity, prodIdsToAccessCodes, encodedString),
                    reservedSeatsState.selectedSeats
                  ).eventToAdd
                }
                handleUpdateTicketUpdateAt={handleUpdateTicketUpdateAt}
              />
              {event.ticketTypes.filter(ticket => {
                return ticket.isPromotionRequired === true || ticket.promotionRequired === true;
              }).length > 0 ? (
                <EventAccessCodeInput
                  prodIdsToAccessCodes={prodIdsToAccessCodes}
                  promoError={promoError}
                  setPromoCode={setPromoCode}
                  promoCode={promoCode}
                  createPromo={createPromotion}
                  removePromo={removePromotion}
                  showApplied={showApplied}
                  setShowApplied={setShowApplied}
                />
              ) : null}
              {allRequireAccessCode && !getTicketsButtonEnabled() ? null : (
                <GetTicketsButton {...testProperties(eventDetailsPage.addToCart)}>
                  <StyledButton
                    title='Add to cart'
                    accessibilityLabel='Add to cart'
                    size='lg'
                    disabled={!getTicketsButtonEnabled() || (isSeason && !seasonHasEvents) || !numberInputState}
                    onPress={addToCart}
                  />
                </GetTicketsButton>
              )}
              <EventFeeInfo
                hasForm={!isEmpty(event?.form) || (event?.ticketTypes || []).some(tk => !isEmpty(tk?.form))}
                hasHiddenFees={!isStateUsingEnhancedFee(financialSchoolState)}
                isTaxEnable={isTaxEnable(taxFlagRules)}
              />
            </View>
          )}
          {/* Fundraisers currently have a unique display for their description */}
          {/* the conditional below prevents duplicate displays of the description until all events share the same UI */}
          {event.description && !isFundraiser && (
            <EventDetails ref={eventVenueDetailsRef} description={event.description} />
          )}
          {event.venue && <VenueLocation venue={event.venue} />}
          {/* code for LD Context sync QA only, to be removed later, is flagged in LD */}
          {isEventContext ? <ExampleEventContextualLDComponent /> : null}
        </EventDetailsWrapper>
      ) : null}
      {isTargetedUser && multiContextIndicator()}
    </EventDetailsContainer>
  );
};
