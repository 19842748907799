import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  container: css`
    align-self: center;
    border-color: rgb(211, 211, 211);
    border-radius: 4px;
    border-width: 2px;
    padding: 16px;
    margin-bottom: 24px;
    width: 100%;
  `,
  header: css`
    align-items: center;
    flex-direction: row;
  `,
  headerTextWrapper: css`
    margin-right: 8px;
  `,
  codeAppliedContainer: css`
    flex-direction: row;
  `,
  iconWrapper: css`
    margin-right: 4px;
  `,
  warningIconWrapper: css`
    position: absolute;
    right: 16px;
    top: 0px;
    bottom: 0px;
    justify-content: center;
    padding-right: 8px;
  `,
  formContainer: css<{ isDesktop: boolean }>`
    flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
    margin-vertical: 8px;
    align-items: flex-start;
    width: 100%;
  `,
  errorWrapper: css`
    margin-left: 8px;
  `,
  applyWrapper: css<{ isDesktop: boolean }>`
    width: ${props => (props.isDesktop ? '' : '100%')};
  `,
  inputWrapper: css<{ isDesktop: boolean }>`
    flex-direction: row;
    margin-bottom: 8px;
    width: 100%;
  `,
  inputContainer: css<{ isDesktop: boolean }>`
    width: ${props => (props.isDesktop ? '60%' : '100%')};
    margin-bottom: ${props => (props.isDesktop ? '0px' : '8px')};
  `,
  input: css<{ hasError: boolean; isDesktop: boolean }>`
    border-color: ${props => (props.hasError ? '#da1e28' : 'rgb(211, 211, 211)')};
    border-radius: 4px;
    border-style: solid;
    border-width: 1.5px;
    height: 40px;
    margin-right: ${props => (props.isDesktop ? '16px' : '0px')};
    padding-left: 16px;
    width: 100%;
  `,
  appliedAccessCodesContainer: css`
    justify-content: center;
  `,
  appliedAccessCodesHeader: css`
    margin-vertical: 8px;
  `,
  appliedAccessCodesWrapper: css`
    background-color: rgba(39, 245, 157, 0.4);
    border-radius: 16px;
    flex-direction: row;
    margin-right: 8px;
    padding-left: 8px;
    padding-right: 4px;
  `,
  accessCodeContainer: css`
    flex-direction: row;
  `
};
