import React from 'react';
import { View } from 'react-native';

import { styles } from './EventAccessCodeItem.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import Locked from '@gf/cross-platform-lib/components/Icons/Locked';
import { StyledText } from '@gf/cross-platform-lib/components';
import Unlocked from '@gf/cross-platform-lib/components/Icons/Unlocked';

const Container = styled(View)`
  ${styles.container}
`;
const IconWrapper = styled(View)`
  ${styles.iconWrapper}
`;

interface EventAccessCodeItemProps {
  accessCode?: string;
}

export const EventAccessCodeItem = ({ accessCode }: EventAccessCodeItemProps) => {
  return (
    <Container>
      {accessCode ? (
        <>
          <IconWrapper>
            <Unlocked height={14} width={14} fill='rgb(82, 82, 82)' />
          </IconWrapper>
          <>
            <StyledText color={'rgb(82, 82, 82)'} typeToken='label01'>
              Unlocked with access code{' '}
            </StyledText>
            <StyledText color={'green'} typeToken='label01SemiBold'>
              {accessCode}
            </StyledText>
          </>
        </>
      ) : (
        <>
          <IconWrapper>
            <Locked height={14} width={14} fill='rgb(82, 82, 82)' />
          </IconWrapper>
          <StyledText color={'rgb(82, 82, 82)'} typeToken='label01'>
            Locked: access code required for purchase
          </StyledText>
        </>
      )}
    </Container>
  );
};

export default EventAccessCodeItem;
