import React from 'react';
import { Pressable, View } from 'react-native';
import { StyledText } from '@gf/cross-platform-lib/components';
import { Close, WarningFilled } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';

import { styles } from './EventAlert.styles';
import styled from '@gf/cross-platform-lib/styled-components';

const AlertTextView = styled(View)`
  ${styles.alertTextView}
`;
const EventAlertContainerInner = styled(View)`
  ${styles.eventAlertContainerInner}
`;
const EventAlertContainerOuter = styled(View)`
  ${styles.eventAlertContainerOuter}
`;
const WarningFilledWrapper = styled(View)`
  ${styles.warningFilledWrapper}
`;
const YellowBar = styled(View)`
  ${styles.yellowBar}
`;

interface EventAlertProps {
  alert: string;
  setShowAlert: Function;
  alertType?: string;
}

export const EventAlert = ({ alert, setShowAlert, alertType = 'Event Alert' }: EventAlertProps) => {
  return (
    <EventAlertContainerOuter>
      <YellowBar />
      <WarningFilledWrapper>
        <WarningFilled figurecolor='#000' color={'rgb(247, 208, 32)'} width={18} height={18} />
      </WarningFilledWrapper>
      <EventAlertContainerInner>
        <StyledText typeToken={'headingCompact01'}>{alertType}</StyledText>
        <AlertTextView>
          <StyledText typeToken={'label02'}>{alert}</StyledText>
        </AlertTextView>
      </EventAlertContainerInner>
      <Pressable onPress={() => setShowAlert(false)} testID='hide-alert-btn'>
        <Close width={18} height={18} style={{ margin: 16 }} color='black' />
      </Pressable>
    </EventAlertContainerOuter>
  );
};

export default EventAlert;
