import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import styled from '@gf/cross-platform-lib/styled-components';
import startCase from 'lodash/startCase';
import { Carbonicons, StyledButton, StyledText } from '@gf/cross-platform-lib/components';
import { styles } from './SeatSelectionCategories.styles';
import { ChartInstance, ReservedSeat, ProductSeating } from '@gf/cross-platform-lib/interfaces';
import { SeatInfo } from './SeatInfo';
import { ReactNode } from 'react';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';
import { useReservedSeating } from '@gf/cross-platform-lib/hooks';
import { correctHexColor } from '@gf/cross-platform-lib/utils';

interface SeatHeldProps {
  isMobile?: boolean;
  category: string;
  seats: ReservedSeat[];
  chartInstance: ChartInstance;
  isEventDetails?: boolean;
  tickets: ProductSeating[];
  eventKey: string;
  eventId: string;
  financialSchoolState: string | undefined;
}

const getSeatLabel = (seat: ReservedSeat['selectedSeat']) => {
  const { labels, parent, objectType } = seat;

  let label = '';
  if (labels.section) {
    label += `${labels.section}, `;
  }
  label += `${startCase(parent.type)} ${labels.parent}, ${startCase(objectType)} ${labels.own}`;

  return label;
};

interface CardWrapperProps {
  children: ReactNode;
  isEventDetails: boolean | undefined;
  chartInstance: ChartInstance;
  selectedSeat: ReservedSeat['selectedSeat'];
  isMobile?: boolean;
}

const CardWrapper: React.FC<CardWrapperProps> = ({
  children,
  isEventDetails,
  chartInstance,
  selectedSeat,
  isMobile
}) => {
  if (isEventDetails) {
    return (
      <EventDetailsCardWrapper key={selectedSeat.id} isMobile={isMobile}>
        {children}
      </EventDetailsCardWrapper>
    );
  }

  return (
    <TouchableOpacity
      onPress={
        chartInstance
          ? () => {
              try {
                chartInstance.zoomToSection(selectedSeat?.labels?.section);
              } catch (e: any) {
                recordError(e, {
                  originatingFunction: 'SeatHeld-CardWrapper-TouchableOpacity-onPress',
                  customMessage: `Failed to zoom to section ${selectedSeat?.labels?.section}. Seat ID: ${selectedSeat.id}`,
                  errorGroup: NEW_RELIC_ERROR_GROUPS.SeatsIo,
                  data: { selectedSeat, chartInstance, isEventDetails }
                });
              }
            }
          : undefined
      }
      key={selectedSeat.id}
    >
      {children}
    </TouchableOpacity>
  );
};

const isMultiTierTicket = (seat: ReservedSeat['selectedSeat']): boolean => {
  return !!seat.category?.pricing?.ticketTypes;
};

export const SeatHeld = ({
  category,
  seats,
  isMobile,
  chartInstance,
  isEventDetails,
  tickets,
  eventKey,
  eventId,
  financialSchoolState
}: SeatHeldProps) => {
  const { removeSeat } = useReservedSeating(eventId);
  const styleProps = { isMobile, isEventDetails };
  return (
    <SelectedCategoriesWrapper {...styleProps}>
      <CategoryTextWrapper isEventDetails={isEventDetails} isMobile={isMobile}>
        <StyledText typeToken='heading02' style={{ width: 300 }}>
          {`${category}`}
        </StyledText>
      </CategoryTextWrapper>
      <CategoriesCardWrapper {...styleProps}>
        {seats.map(({ price, selectedSeat, ticketType }) => {
          const matchingTicket = tickets.find(item => {
            if (isMultiTierTicket(selectedSeat)) {
              return item.name?.toUpperCase() === ticketType?.toUpperCase() && item.price === price;
            } else {
              return item.seatsIoCategory?.toUpperCase() === ticketType?.toUpperCase() && item.price === price;
            }
          });

          const sectionName = isMultiTierTicket(selectedSeat) ? matchingTicket?.name : matchingTicket?.seatsIoCategory;
          const ticketName = sectionName || ticketType || '';
          const fee = matchingTicket?.fee || 0;
          const seatColor = selectedSeat.category.color;

          return (
            <CardWrapper
              key={selectedSeat.id}
              isEventDetails={isEventDetails}
              chartInstance={chartInstance}
              selectedSeat={selectedSeat}
              isMobile={isMobile}
            >
              <SelectedCategoryCard seatColor={correctHexColor(seatColor)} {...styleProps}>
                <CardTop>
                  <SelectedCardLeft isEventDetails={isEventDetails}>
                    <SeatInfo
                      price={price}
                      seatLabel={getSeatLabel(selectedSeat)}
                      selectedTicketType={selectedSeat.selectedTicketType}
                      isAccessible={selectedSeat.accessible || selectedSeat.category.accessible}
                      isEventDetails={isEventDetails}
                      categoryLabel={selectedSeat.category.label}
                      ticketName={ticketName}
                      fee={fee}
                      financialSchoolState={financialSchoolState}
                    />
                  </SelectedCardLeft>
                  <CardRight isEventDetails={isEventDetails}>
                    {isEventDetails ? (
                      <TrashWrapper
                        onPress={async () => {
                          try {
                            await removeSeat(selectedSeat.id, eventKey);
                          } catch (error) {
                            console.error('Error removing seat', error);
                            recordError('Failed to Remove Seat', {
                              originatingFunction: 'SeatHeld-CardWrapper-TrashWrapper-onPress',
                              customMessage: `Failed to remove seat. Category: ${selectedSeat.category.label}, Seat ID: ${selectedSeat.id}`,
                              errorGroup: NEW_RELIC_ERROR_GROUPS.SeatsIo,
                              data: { selectedSeat, isEventDetails }
                            });
                          }
                        }}
                      >
                        <Carbonicons name='trashCan' size={16} />
                      </TrashWrapper>
                    ) : (
                      <StyledButton
                        title='X'
                        accessibilityLabel='deselect seat'
                        buttonType='tertiary'
                        width='32px'
                        height='32px'
                        padding='0px'
                        onPress={
                          chartInstance
                            ? () => {
                                try {
                                  chartInstance.deselectObjects([selectedSeat.id]);
                                } catch (e: any) {
                                  recordError(e, {
                                    originatingFunction: 'SeatHeld-CardWrapper-StyledButton-onPress',
                                    customMessage:
                                      'Failed to deselect object via x button. Category: ${selectedSeat.category.label}, Seat ID: ${selectedSeat.id}',
                                    errorGroup: NEW_RELIC_ERROR_GROUPS.SeatsIo,
                                    data: { selectedSeat, chartInstance, isEventDetails }
                                  });
                                }
                              }
                            : undefined
                        }
                      />
                    )}
                  </CardRight>
                </CardTop>
              </SelectedCategoryCard>
            </CardWrapper>
          );
        })}
      </CategoriesCardWrapper>
    </SelectedCategoriesWrapper>
  );
};

const SelectedCategoriesWrapper = styled(View)`
  ${styles.selectedCategoriesWrapper}
`;

const CategoryTextWrapper = styled(View)`
  ${styles.categoryTextWrapper}
`;

const SelectedCategoryCard = styled(View)`
  ${styles.selectedCategoryCard}
`;
const CategoriesCardWrapper = styled(View)`
  ${styles.categoriesCardWrapper}
`;
const SelectedCardLeft = styled(View)`
  ${styles.selectedCardLeft}
`;
const CardRight = styled(View)`
  ${styles.cardRight}
`;
const CardTop = styled(View)`
  ${styles.cardTop}
`;
const TrashWrapper = styled.TouchableOpacity`
  ${styles.trashWrapper}
`;

const EventDetailsCardWrapper = styled(View)`
  ${styles.cardWrapper}
`;

export default SeatHeld;
