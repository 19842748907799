import React from 'react';
import { View, Text } from 'react-native';
import { styles } from './PriceDisplay.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { eventDetailsPage, testProperties } from '@gf/cross-platform-lib/utils/testProperties';

const PriceDisplayContainer = styled(View)`
  ${styles.priceDisplayContainer}
`;

const Title = styled(Text)`
  ${styles.title}
`;

const Subtitle = styled(Text)`
  ${styles.subtitle}
`;

export type PriceDisplayProps = {
  totalPrice: string;
  ticketPrice: string;
  enhancedFeeEnabled: boolean;
  feePrice?: string | null;
};

export const PriceDisplay = ({ totalPrice, ticketPrice, enhancedFeeEnabled, feePrice }: PriceDisplayProps) => {
  if (enhancedFeeEnabled && feePrice)
    return (
      <PriceDisplayContainer>
        <Title {...testProperties(eventDetailsPage.product.price)}>{totalPrice}</Title>
        <Subtitle {...testProperties(eventDetailsPage.product.fee)}>
          {ticketPrice} price + {feePrice} fee
        </Subtitle>
      </PriceDisplayContainer>
    );
  else
    return (
      <PriceDisplayContainer>
        <Title
          typeToken='label03'
          style={{ fontFamily: 'Roboto-Regular' }}
          {...testProperties(eventDetailsPage.product.price)}
        >
          {ticketPrice}
        </Title>
      </PriceDisplayContainer>
    );
};

export default PriceDisplay;
