import React from 'react';

import { GFImage, Link, StyledText } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { EventAvailabilityMessage } from '../../util';
import { EventSoldOut } from './EventSoldOut';

import { styles } from './EventUnavailable.style';
import styled from '@gf/cross-platform-lib/styled-components';

const EventUnavailableContainer = styled(View)`
  ${styles.eventUnavailableContainer}
`;
const EventUnavailableTextWrapper = styled(View)`
  ${styles.eventUnavailableTextWrapper}
`;

interface EventUnavailableProps {
  message: string;
  schoolHuddleId: string;
  schoolName: string;
}

export const EventUnavailable = ({ message, schoolHuddleId, schoolName }: EventUnavailableProps) => {
  const schoolPath = GOFAN_APP_PAGES.school.getPath(schoolHuddleId);
  const TICKETS_PNG = 'opaque-tickets.png';

  return (
    <>
      {message === EventAvailabilityMessage.SOLD_OUT ? (
        <EventSoldOut />
      ) : (
        <EventUnavailableContainer>
          <GFImage width={140} height={140} name={TICKETS_PNG} />
          <StyledText textAlign={'center'} typeToken={'label02'} style={{ marginTop: 24 }}>
            {message}
          </StyledText>
          <EventUnavailableTextWrapper>
            <StyledText textAlign={'center'} typeToken={'label02'}>
              See what other events are happening at{' '}
              <Link href={schoolPath}>
                <StyledText textAlign={'center'} typeToken={'headingCompact01'}>
                  {schoolName}.
                </StyledText>
              </Link>
            </StyledText>
          </EventUnavailableTextWrapper>
        </EventUnavailableContainer>
      )}
    </>
  );
};

export default EventUnavailable;
