import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { styles } from './FundraiserDescription.styles';

export type FundraiserDescriptionProps = {
  description: string;
  setDoesDescriptionOverflow: React.Dispatch<React.SetStateAction<boolean>>;
  buttonHeight: number;
  containerHeight: number;
};

const Title = styled.div`
  ${styles.title}
`;

export const FundraiserDescription = ({
  description,
  setDoesDescriptionOverflow,
  buttonHeight,
  containerHeight
}: FundraiserDescriptionProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const resizeObserver = new ResizeObserver(entries => {
    const height = entries[0].target.scrollHeight;
    setDoesDescriptionOverflow(height > containerHeight - buttonHeight);
  });

  useEffect(() => {
    if (ref && ref.current) resizeObserver.observe(ref.current);
    return () => {
      if (ref && ref.current) resizeObserver.unobserve(ref.current);
    };
  }, []);

  return <Title ref={ref} dangerouslySetInnerHTML={{ __html: description }} />;
};

export default FundraiserDescription;
