import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css`
    display: flex;
    margin-top: 16px;
  `,

  reservedSeatingWrapper: css<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '100%' : '576px')};
    border: 2px solid rgb(211, 211, 211);
    border-radius: 1px;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'column')};
    justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'space-between')};
  `,
  textWrapper: css<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '144px' : '144px')};
    height: ${({ isMobile }) => (isMobile ? '46px' : '44px')};
    justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'center')};
    color: rgb(50, 50, 50);
  `,
  leftSideWrapper: css<{ isMobile: boolean }>`
    flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
    justify-content: space-between;
  `,
  topWrapper: css`
    width: 100%;
    height: 34px;
  `,
  middleWrapper: css<{ isMobile: boolean }>`
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    padding: 16px
    width: 100%;
    height: ${({ isMobile }) => (isMobile ? '142px' : '118px')};
    justify-content: space-between;
  `,
  buttonWrapper: css<{ isMobile: boolean }>`
    justify-content: center;
    width: ${({ isMobile }) => (isMobile ? '100%' : '159px')};
    padding-top: ${({ isMobile }) => (isMobile ? '24px' : '0px')};
  `,
  showTicketsWrapper: css<{ isMobile: boolean }>`
    width: 57px;
    justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  `,
  dropDownWrapper: css`
    flex-direction: row;
  `,
  iconWrapper: css`
    padding-left: 4px;
  `,
  bottomWrapper: css<{}>`
    width: 100%;
  `
};
