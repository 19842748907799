import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  eventTicketsContainer: css`
    margin-bottom: 15px;
    width: 100%;
  `,
  eventTicketsFlatList: css`
    margin-top: 10px;
    margin-bottom: 15px;
  `
};
