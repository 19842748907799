import React from 'react';
import { Platform } from 'react-native';

import { StyledButton, StyledText } from '@gf/cross-platform-lib/components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { View } from 'react-native';

import { styles } from './EventSelectOptions.styles';
import styled from '@gf/cross-platform-lib/styled-components';

const EventSelectOptionsContainer = styled(View)`
  ${styles.eventSelectOptionsContainer}
`;

interface EventSelectOptions {
  scrollToEventDetails: Function;
}

export const EventSelectOptions = ({ scrollToEventDetails }: EventSelectOptions) => {
  const { isMobile } = useMediaQuery();

  return (
    <EventSelectOptionsContainer isMobile={isMobile || Platform.OS === 'android' || Platform.OS === 'ios'}>
      {isMobile || Platform.OS === 'android' || Platform.OS === 'ios' ? null : (
        <StyledText typeToken={'heading02'}>Select Options</StyledText>
      )}
      <StyledButton
        onPress={() => scrollToEventDetails()}
        title='View event detail'
        size='sm'
        buttonType='tertiary'
        accessibilityLabel='View event detail'
      />
    </EventSelectOptionsContainer>
  );
};

export default EventSelectOptions;
