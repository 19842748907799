import { Text, View } from 'react-native';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { styles } from './EventFeeInfo.styles';
import styled from '@gf/cross-platform-lib/styled-components';

const EventFeeInfoContainer = styled(View)`
  ${styles.eventFeeInfoContainer}
`;

const EventFeeInfoText = styled(Text)`
  ${styles.eventFeeInfoText}
`;

interface EventFeeInfoProps {
  hasForm: boolean;
  hasHiddenFees: boolean;
  isTaxEnable: boolean;
}

export const EventFeeInfo = ({ hasForm, hasHiddenFees, isTaxEnable }: EventFeeInfoProps) => {
  let subTitle = '';
  if (hasHiddenFees && isTaxEnable) {
    subTitle = 'Additional taxes and fees may apply.';
  } else if (hasHiddenFees) {
    subTitle = 'Additional fees may apply.';
  } else if (isTaxEnable) {
    subTitle = 'Additional taxes may apply.';
  }

  return (
    <EventFeeInfoContainer>
      {hasForm && <EventFeeInfoText>Additional information will be required to complete purchase.</EventFeeInfoText>}
      {!isEmpty(subTitle) ? <EventFeeInfoText>{subTitle}</EventFeeInfoText> : <></>}
      <EventFeeInfoText>All purchases are non-refundable.</EventFeeInfoText>
    </EventFeeInfoContainer>
  );
};

export default EventFeeInfo;
