import { Text, View } from 'react-native';
import React from 'react';

import { styles } from './FundraiserMetrics.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { activityIcons } from '@gf/cross-platform-lib/constants/';
import { Carbonicons, GFImage } from '@gf/cross-platform-lib/components';
import pluralize from 'pluralize';
import { testProperties } from '@gf/cross-platform-lib/utils';

const FundraiserMetricsContainer = styled(View)`
  ${styles.fundraiserMetricsContainer}
`;

const InfoContainer = styled(View)`
  ${styles.infoContainer}
`;

const VerticalDivider = styled(View)`
  ${styles.verticalDivider}
`;

const MetricsText = styled(Text)`
  ${styles.metricsText}
`;

interface FundraiserMetricsProps {
  donationsMade: number;
  daysLeft: number;
}

export const FundraiserMetrics = ({ donationsMade, daysLeft }: FundraiserMetricsProps) => {
  return (
    <FundraiserMetricsContainer>
      {daysLeft > 0 && (
        <InfoContainer {...testProperties('days-left')}>
          <Carbonicons name='time' size={18} color='rgb(82, 82, 82)' />
          <MetricsText>
            {daysLeft} {pluralize('day', daysLeft)} left
          </MetricsText>
        </InfoContainer>
      )}
      {daysLeft > 0 && donationsMade > 0 && <VerticalDivider></VerticalDivider>}
      {donationsMade > 0 && (
        <InfoContainer {...testProperties('donations-made')}>
          <GFImage name={activityIcons.fundraiser} width={18} height={18} tintColor='#525252' />
          <MetricsText>
            {donationsMade} {pluralize('donation', donationsMade)} made
          </MetricsText>
        </InfoContainer>
      )}
    </FundraiserMetricsContainer>
  );
};

export default FundraiserMetrics;
