import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  priceDisplayContainer: css`
    ${baseStyles.priceDisplayContainer}
  `,
  title: css`
    ${baseStyles.title}
  `,
  subtitle: css`
    ${baseStyles.subtitle}
  `
};
