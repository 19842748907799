import { Platform, Linking } from 'react-native';

export const openMapNavigation = (
  street: string | undefined,
  city: string | undefined,
  state: string | undefined,
  zipCode: string | undefined
) => {
  const venueAddress = `${street}, ${city}, ${state} ${zipCode}`;
  Platform.OS == 'ios'
    ? Linking.openURL(`http://maps.apple.com/maps?daddr=${venueAddress}`)
    : Linking.openURL(`http://maps.google.com/maps?daddr=${venueAddress}`);
};
