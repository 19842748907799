import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { Tooltip } from '../Tooltip';

import { styles } from './EventTooltip.styles';

const Wrapper = styled.View`
  ${styles.wrapper}
`;
const EventTooltipContainer = styled.View`
  ${styles.eventTooltipContainer}
`;

const EventTooltipText = styled.Text`
  ${styles.eventTooltipText}
`;

export const EventTooltip = () => {
  return (
    <Wrapper>
      <EventTooltipContainer>
        <Tooltip />
        <EventTooltipText>Your phone is your ticket</EventTooltipText>
      </EventTooltipContainer>
    </Wrapper>
  );
};
