import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  eventDetailsContainer: css<{ isWeb?: boolean }>`
    align-self: center;
    display: flex;
    flex-direction: column;
    padding: ${({ isWeb }) => (isWeb ? '16px' : '16px 0px 16px 0px')};
    max-width: 608px;
    width: 100%;
  `,
  getTicketsButton: css`
    align-self: center;
    margin-bottom: 16px;
    margin-top: 8px;
  `,
  seatsIoWrapper: css`
    width: 17px;
    height: 17px;
    opacity: 0;
  `,
  eventDetailsWrapper: css`
    padding-horizontal: 16px;
  `,
  skeletonWrapper: css`
    padding-top: 48px;
  `
};
