import * as React from 'react';
import Svg, { G, Rect, Path, SvgProps } from 'react-native-svg';

function NoTickets(props: SvgProps) {
  return (
    <Svg width='125px' height='133px' viewBox='0 0 125 133' {...props}>
      <G fillRule='nonzero' stroke='none' strokeWidth={1} fill='none'>
        <G transform='translate(-730 -391) translate(730 391) rotate(-33 95.61 28.321)'>
          <Rect fill='#FFF' x={-2.06053686e-13} y={-5.33746196e-14} width={104} height={69} rx={4} />
          <Path
            d='M93.368 4c2.926.008 5.56 1.848 6.395 4.71.276.988.235 2.001.235 3.023l-.001 10.505-.002 15.71-.002 20.507v.065c-.025 1.16-.317 2.294-.925 3.275a6.614 6.614 0 01-5.537 3.122c-.17.008-.34 0-.518 0-1.176.008-2.351 0-3.518 0l-14.105.002-18.14.002-15.71.002h-6.696c-.632 0-1.126-.526-1.142-1.143-.049-1.767-1.475-3.226-3.259-3.225-1.791-.008-3.21 1.46-3.259 3.226-.016.616-.51 1.143-1.143 1.143h-.778l-11.121.002c-1.2 0-2.375.008-3.567 0-2.934-.016-5.6-1.985-6.322-4.863-.405-1.589-.202-3.445-.202-5.066l.001-9.614.001-12.564c0-4.037-.007-8.065.002-12.094V12.36l.001-1.767c0-2.286 1.111-4.386 3.048-5.61 1.46-.924 3.048-.973 4.686-.973L26.13 4c.631 0 1.142.527 1.15 1.15.032 1.833 1.435 3.316 3.315 3.251 1.743-.057 3.17-1.516 3.202-3.25a1.152 1.152 0 011.143-1.144h7.88l14.039-.002 16.017-.002L86.56 4h6.809zM30.586 16.506l-.154.001c-1.24.057-2.294 1.013-2.294 2.294l-.001 10.587-.002 16.876c0 1.28.008 2.57 0 3.859 0 1.2 1.053 2.35 2.293 2.293 1.24-.057 2.294-1.013 2.294-2.294l.001-10.586.002-16.877c0-1.28-.007-2.57 0-3.858 0-1.2-1.053-2.35-2.293-2.294zm39.692 23.39l-17.85.002H48.36c-1.2 0-2.351 1.055-2.294 2.295.056 1.24 1.013 2.294 2.293 2.294l11.203-.002 17.849-.002h4.07c1.199 0 2.35-1.054 2.293-2.294-.056-1.24-1.013-2.294-2.285-2.286-3.737-.008-7.474 0-11.21-.007zM56.766 24.433h-8.404c-1.2 0-2.351 1.054-2.295 2.294.057 1.24 1.013 2.294 2.294 2.294l7.182-.001 11.51-.002h2.643c1.2 0 2.35-1.054 2.294-2.294-.057-1.24-1.013-2.294-2.286-2.286l-7.181.001c-3.835-.008-7.677-.007-11.519-.007z'
            fill={props.color ? props.color : '#DBDBDB'}
          />
        </G>
        <G transform='translate(-730 -391) translate(730 391) translate(12.833 63.592)'>
          <Rect fill='#FFF' x={0} y={0} width={104} height={69} rx={4} />
          <Path
            d='M93.368 4c2.926.008 5.56 1.848 6.395 4.71.276.988.235 2.001.235 3.023l-.001 10.505-.002 15.71-.002 20.507v.065c-.025 1.16-.317 2.294-.925 3.275a6.614 6.614 0 01-5.537 3.122c-.17.008-.34 0-.518 0-1.176.008-2.351 0-3.518 0l-14.105.002-18.14.002-15.71.002h-6.696c-.632 0-1.126-.526-1.142-1.143-.049-1.767-1.475-3.226-3.259-3.225-1.791-.008-3.21 1.46-3.259 3.226-.016.616-.51 1.143-1.143 1.143h-.778l-11.121.002c-1.2 0-2.375.008-3.567 0-2.934-.016-5.6-1.985-6.322-4.863-.405-1.589-.202-3.445-.202-5.066l.001-9.614.001-12.564c0-4.037-.007-8.065.002-12.094V12.36l.001-1.767c0-2.286 1.111-4.386 3.048-5.61 1.46-.924 3.048-.973 4.686-.973L26.13 4c.631 0 1.142.527 1.15 1.15.032 1.833 1.435 3.316 3.315 3.251 1.743-.057 3.17-1.516 3.202-3.25a1.152 1.152 0 011.143-1.144h7.88l14.039-.002 16.017-.002L86.56 4h6.809zM30.586 16.506l-.154.001c-1.24.057-2.294 1.013-2.294 2.294l-.001 10.587-.002 16.876c0 1.28.008 2.57 0 3.859 0 1.2 1.053 2.35 2.293 2.293 1.24-.057 2.294-1.013 2.294-2.294l.001-10.586.002-16.877c0-1.28-.007-2.57 0-3.858 0-1.2-1.053-2.35-2.293-2.294zm39.692 23.39l-17.85.002H48.36c-1.2 0-2.351 1.055-2.294 2.295.056 1.24 1.013 2.294 2.293 2.294l11.203-.002 17.849-.002h4.07c1.199 0 2.35-1.054 2.293-2.294-.056-1.24-1.013-2.294-2.285-2.286-3.737-.008-7.474 0-11.21-.007zM56.766 24.433h-8.404c-1.2 0-2.351 1.054-2.295 2.294.057 1.24 1.013 2.294 2.294 2.294l7.182-.001 11.51-.002h2.643c1.2 0 2.35-1.054 2.294-2.294-.057-1.24-1.013-2.294-2.286-2.286l-7.181.001c-3.835-.008-7.677-.007-11.519-.007z'
            fill={props.color ? props.color : '#DBDBDB'}
          />
        </G>
      </G>
    </Svg>
  );
}

export default NoTickets;
