import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css`
    z-index: 99;
  `,
  eventTooltipContainer: css`
    align-items: center;
    background: rgb(211, 211, 211);
    border-radius: 4px;
    flex-direction: row;
    height: 40px;
    justify-content: center;
    width: 100%;
  `,
  eventTooltipText: css`
    color: rgb(22, 22, 22);
    font-family: 'Rubik-SemiBold';
    font-weight: 600;
    margin-left: 8px;
  `
};
