import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  venueLocationContainer: css`
    margin-top: 16px;
  `,
  venueLocationDetailsContainer: css`
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgb(219, 219, 219);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 16px;
    padding: 16px;
    width: 100%;
  `,
  addressContainer: css<{ isNativeApp: boolean }>`
    padding-right: 12px;
    max-width: ${({ isNativeApp }) => (isNativeApp ? '100%' : 'fit-content')};
  `,
  getDirectionsButton: css<{ isMobile: boolean }>`
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgb(50, 50, 50);
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${({ isMobile }) => (isMobile ? '12px' : '0')};
    padding: 11px 20px 11px 16px;
    width: ${({ isMobile }) => (isMobile ? '100%' : '152px')};
  `
};
