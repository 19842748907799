import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  container: css`
    ${baseStyles.container}
  `,
  header: css`
    ${baseStyles.header}
  `,
  headerTextWrapper: css`
    ${baseStyles.headerTextWrapper}
  `,
  codeAppliedContainer: css`
    ${baseStyles.codeAppliedContainer}
  `,
  iconWrapper: css`
    ${baseStyles.iconWrapper}
  `,
  warningIconWrapper: css`
    ${baseStyles.warningIconWrapper}
  `,
  errorWrapper: css`
    ${baseStyles.errorWrapper}
  `,
  formContainer: css`
    ${baseStyles.formContainer}
  `,
  applyWrapper: css`
    ${baseStyles.applyWrapper}
  `,
  inputWrapper: css`
    ${baseStyles.inputWrapper}
  `,
  inputContainer: css`
    ${baseStyles.inputContainer}
  `,
  input: css`
    ${baseStyles.input}
  `,
  appliedAccessCodesContainer: css`
    ${baseStyles.appliedAccessCodesContainer}
  `,
  appliedAccessCodesHeader: css`
    ${baseStyles.appliedAccessCodesHeader}
  `,
  appliedAccessCodesWrapper: css`
    ${baseStyles.appliedAccessCodesWrapper}
  `
};
