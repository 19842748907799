import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  eventUnavailableContainer: css`
    ${baseStyles.eventUnavailableContainer}
  `,
  eventUnavailableTextWrapper: css`
    ${baseStyles.eventUnavailableTextWrapper}
  `,
  soldOut: css`
    ${baseStyles.soldOut}
  `
};
