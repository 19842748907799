import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  eventAlertContainerInner: css`
    flex: 1;
    flex-direction: column;
    padding-top: 12px;
    padding-bottom: 12px;
  `,
  eventAlertContainerOuter: css`
    background-color: rgba(253, 209, 58, 0.3);
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    width: 100%;
  `,
  alertTextView: css`
    flex-shrink: 1;
    margin-top: 4px;
  `,
  warningFilledWrapper: css`
    padding: 12px;
  `,
  yellowBar: css`
    background-color: rgb(247, 208, 32);
    height: 100%;
    width: 3px;
  `
};
