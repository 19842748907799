import React from 'react';
import { View } from 'react-native';

import { styles } from './EventTicketsAccessCodeOnly.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { StyledText } from '@gf/cross-platform-lib/components';
import NoTickets from '@gf/cross-platform-lib/components/Icons/NoTickets';

const Container = styled(View)`
  ${styles.container}
`;
const Header = styled(View)`
  ${styles.header}
`;

interface EventTicketsAccessCodeOnlyProps {}

export const EventTicketsAccessCodeOnly = ({}: EventTicketsAccessCodeOnlyProps) => {
  return (
    <Container>
      <NoTickets height={140} width={140} />
      <Header>
        <StyledText typeToken='bodyCompact01SemiBold'>Access code required to make a purchase at this time</StyledText>
      </Header>
      <StyledText typeToken='label02'>
        To make a purchase for this event, please enter your access code below
      </StyledText>
    </Container>
  );
};

export default EventTicketsAccessCodeOnly;
