import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  eventDetailsContainer: css`
    ${baseStyles.eventDetailsContainer}
  `,
  getTicketsButton: css`
    ${baseStyles.getTicketsButton};
  `
};
