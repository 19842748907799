import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './EventDetailSkeleton.styles';
import {
  Skeleton,
  Skeleton16,
  SkeletonRow,
  SkeletonBorderRadius4,
  SkeletonColumn,
  Skeleton24,
  SkeletonWrapper,
  Skeleton20
} from '@gf/cross-platform-lib/components';
import { ScrollView } from 'react-native';

const Container = styled.View`
  ${styles.container}
`;
const ContainerMobile = styled.View`
  ${styles.containerMobile}
`;

const ItemWrapper = styled.View`
  ${styles.itemWrapper}
`;

export const EventDetailSkeleton = ({
  isMobile,
  isShowLayout = true
}: {
  isMobile: boolean;
  isShowLayout?: boolean;
}) => {
  const renderHeader = () => (
    <ItemWrapper>
      <SkeletonRow alignItems='top' padding='0 32px'>
        <SkeletonBorderRadius4 height='104px' width='104px' margin='0 24px 0 0' />
        <SkeletonColumn>
          <Skeleton20 width='250px' />
          <Skeleton height='36px' width='225px' margin='4px 0 8px 0' />
          <Skeleton20 width='300px' />
          <Skeleton20 width='120px' margin='6px 0 0 0' />
        </SkeletonColumn>
      </SkeletonRow>
    </ItemWrapper>
  );

  const renderHeaderMobile = () => (
    <ItemWrapper>
      <Skeleton16 width='264px' />
      <SkeletonRow margin='8px 0 24px 0' alignItems='top'>
        <SkeletonBorderRadius4 height='64px' width='64px' margin='0 16px 0 0' />
        <SkeletonColumn>
          <Skeleton24 width='210px' />
          <Skeleton24 width='175px' margin='4px 0 8px 0' />
          <Skeleton16 width='210px' />
          <Skeleton16 width='175px' margin='4px 0 4px 0' />
          <Skeleton16 width='100px' />
          <SkeletonBorderRadius4 height='32px' width='197px' margin='24px 0 0 0' />
        </SkeletonColumn>
      </SkeletonRow>
    </ItemWrapper>
  );

  const renderEventTickets = () => (
    <>
      <SkeletonWrapper height='132px' padding='16px' margin='16px 0'>
        <Skeleton24 width='120px' />
        <Skeleton16 width='104px' margin='4px 0 16px 0' />
        <SkeletonBorderRadius4 height='44px' width='100%' />
      </SkeletonWrapper>
      <SkeletonWrapper height='96px' padding='16px'>
        <SkeletonRow isSpaceBetween>
          <SkeletonColumn>
            <Skeleton24 width='72px' />
            <Skeleton16 width='32px' margin='4px 0 4px 0' />
            <Skeleton16 width='64px' />
          </SkeletonColumn>
          <SkeletonBorderRadius4 height='44px' width='148px' />
        </SkeletonRow>
      </SkeletonWrapper>
      <SkeletonBorderRadius4 height='48px' width='190px' margin='24px 0 16px 0' />
      <Skeleton16 width='240px' />
      <Skeleton16 width='225px' margin='4px 0 24px 0' />
    </>
  );

  const renderEventTicketsMobile = () => (
    <>
      <SkeletonWrapper height='132px' padding='16px' margin='16px 0'>
        <Skeleton24 width='120px' />
        <Skeleton16 width='104px' margin='4px 0 16px 0' />
        <SkeletonBorderRadius4 height='44px' width='100%' />
      </SkeletonWrapper>
      <SkeletonWrapper height='96px' padding='16px'>
        <SkeletonRow isSpaceBetween>
          <SkeletonColumn>
            <Skeleton24 width='72px' />
            <Skeleton16 width='32px' margin='4px 0 4px 0' />
            <Skeleton16 width='64px' />
          </SkeletonColumn>
          <SkeletonBorderRadius4 height='44px' width='148px' />
        </SkeletonRow>
      </SkeletonWrapper>
      <SkeletonBorderRadius4 height='48px' width='190px' margin='24px 0 16px 0' />
      <Skeleton16 width='240px' />
      <Skeleton16 width='225px' margin='4px 0 24px 0' />
    </>
  );

  const renderLocation = () => (
    <ItemWrapper>
      <Skeleton24 width='64px' />
      <SkeletonWrapper height='106px' padding='16px' margin='24px 0 16px 0'>
        <SkeletonRow isSpaceBetween>
          <SkeletonColumn>
            <Skeleton16 width='120px' />
            <Skeleton16 width='152px' margin='4px 0 4px 0' />
            <Skeleton16 width='142px' />
          </SkeletonColumn>
          <SkeletonBorderRadius4 height='44px' width='152px' />
        </SkeletonRow>
      </SkeletonWrapper>
    </ItemWrapper>
  );

  const renderLocationMobile = () => (
    <ItemWrapper>
      <Skeleton16 width='64px' />
      <SkeletonWrapper height='160px' padding='16px' margin='24px 0'>
        <Skeleton16 width='120px' />
        <Skeleton16 width='152px' margin='4px 0 4px 0' />
        <Skeleton16 width='142px' />
        <SkeletonBorderRadius4 height='48px' width='100%' margin='24px 0 0 0' />
      </SkeletonWrapper>
    </ItemWrapper>
  );

  return isMobile ? (
    <ScrollView>
      {isShowLayout && <Skeleton height='48' width='100%' borderRadius='0' />}
      <ContainerMobile>
        {renderHeaderMobile()}
        <SkeletonBorderRadius4 height='40px' margin='16px 0' />
        {renderEventTicketsMobile()}
        {renderLocationMobile()}
      </ContainerMobile>
    </ScrollView>
  ) : (
    <>
      {isShowLayout && <Skeleton height='48' width='100%' borderRadius='0' />}
      <ScrollView>
        <Container>
          {renderHeader()}
          <SkeletonBorderRadius4 height='40px' width='576px' margin='6px 0 16px 0' />
          {renderEventTickets()}
          {renderLocation()}
          <SkeletonBorderRadius4 height='32px' width='137px' />
        </Container>
      </ScrollView>
    </>
  );
};
