import { css } from 'styled-components';

export const styles = {
  container: css`
    padding: 16px;
    width: 608px;
    align-self: center;
    align-items: center;
  `,
  containerMobile: css`
    padding: 32px 20px 32px 20px;
    width: 100%;
    align-items: center;
  `,
  itemWrapper: css`
    align-items: flex-start;
    width: 100%;
  `
};
