import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function Locked(props: SvgProps) {
  return (
    <Svg id='gf_icon_locked' aria-label='Locked icon' viewBox='0 0 32 32' {...props}>
      <Path d='M24 14h-2V8a6 6 0 00-12 0v6H8a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V16a2 2 0 00-2-2zM12 8a4 4 0 018 0v6h-8zm12 20H8V16h16z' />
      <Path
        id='Locked_Transparent_Rectangle_'
        data-name='&lt;Transparent Rectangle&gt;'
        d='M0 0H32V32H0z'
        fill='none'
      />
    </Svg>
  );
}

export default Locked;
