import { TicketCart } from '@gf/cross-platform-lib/models';
import { reachedLimit } from '@gf/cross-platform-lib/utils';

export const getTotalQuantity = (tickets: TicketCart[]) => {
  let total = 0;
  tickets.forEach(
    eventTicket =>
      (total += eventTicket?.packCount > 1 ? eventTicket.quantity * eventTicket.packCount : eventTicket.quantity)
  );
  return total;
};

export const getSchoolLimit = (schoolsTicket: Array<{ schoolLimit: number; schoolId: string }>, schoolId: string) =>
  schoolsTicket.find((school: any) => `${school.schoolId}` === `${schoolId}`)?.schoolLimit || 0;

export const isDisabledIncreaseButton = (
  limitTypes: { [p: string]: number },
  quantity: number,
  packCount: number = 1,
  totalQuantity: number
) => {
  const ticketQty = (type: string) =>
    type === 'ticketLimit' || type === 'ticketLimitPerOrder' ? quantity * packCount : totalQuantity;

  return Object.entries(limitTypes).some(([limitType, limitValue]) => {
    return reachedLimit(ticketQty(limitType), limitValue || 0, packCount);
  });
};
