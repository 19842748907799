import { AccessCodeInformation, ProductSeating, Promotion } from '@gf/cross-platform-lib/interfaces';
import { createPromotionLock, deletePromoLock, updatePromoLock } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import find from 'lodash/find';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS, ORDER_LIMIT } from '@gf/cross-platform-lib/constants';
import { convertEventSalesInfo } from '@gf/cross-platform-lib/utils';
import isEmpty from 'lodash/isEmpty';
import { getPackCountQuantity } from '../../../review-and-buy/hooks';
import { EventCart } from '@gf/cross-platform-lib/models';

const findAccessCodeExisting = (tickets: ProductSeating[], productId: string | number) =>
  find(tickets, function (ticket: ProductSeating) {
    if (ticket.id.toString() === productId.toString()) {
      return ticket;
    }
  }) as ProductSeating | undefined;

export const createPromo = async (
  trackPromoteCodeEntered: Function,
  trackPromoteCodeDenied: Function,
  trackPromoteCodeApplied: Function,
  promoCode: string,
  setPromoCode: Function,
  setPromoCodeId: Function,
  setPromoError: Function,
  eventId: string,
  ticketsToQuantity: Map<ProductSeating, number>,
  prodIdsToAccessCodes: Map<number, AccessCodeInformation>,
  setProdIdsToAccessCodes: Function,
  setTicketsToQuantity: Function,
  setAccessCodes: Function
) => {
  trackPromoteCodeEntered({
    promotion: {
      codeName: promoCode || '',
      codeId: '',
      discount: undefined,
      codeAccess: undefined
    },
    promotions: undefined,
    inCart: false
  });
  const promoResponse = await createPromotionLock(parseInt(eventId), promoCode);
  if ('message' in promoResponse) {
    trackPromoteCodeDenied({
      promotion: {
        codeName: promoCode || '',
        codeId: '',
        discount: undefined,
        codeAccess: 'access_code',
        reason: promoResponse.message
      },
      promotions: undefined,
      inCart: false
    });
    const promoResponseMessage = promoResponse.message;
    if (promoResponseMessage.includes('Promo Code is not Found for request')) {
      setPromoError('Access code is not valid. Please try again.');
    } else if (promoResponseMessage.includes('Promo has exceeded Usage Limit')) {
      setPromoError('This access code has reached its maximum number of uses.');
    } else if (promoResponseMessage.includes('Promo code will be available on')) {
      const splitDate = promoResponseMessage.split('.')[0].split(' ');
      const date = `${splitDate[6]} ${splitDate[7]} ${splitDate[8]}`;
      setPromoError(`Access code will be available on ${date}.`);
    } else if (promoResponseMessage.includes('We’re sorry, the promo code has expired')) {
      setPromoError(`Access code not valid at this time.`);
    }
  } else {
    const appliedPromo: Promotion = promoResponse as Promotion;
    const code = appliedPromo?._embedded?.promotion?.code;
    const productIds = appliedPromo?._embedded?.promotion?.productAssociations.map(product => product.productId);
    setPromoCodeId(appliedPromo?.promoId ? appliedPromo?.promoId.toString() : '');
    setAccessCodes((prevState: Promotion[]) => [...prevState, appliedPromo]);
    trackPromoteCodeApplied({
      promotion: {
        codeName: promoCode || '',
        codeId: appliedPromo.promoId ? appliedPromo.promoId.toString() : '',
        discount: 0,
        codeAccess: 'access_code'
      },
      promotions: undefined,
      inCart: false
    });

    const foundTicket = productIds.some(id => findAccessCodeExisting(Array.from(ticketsToQuantity.keys()), id));

    productIds.forEach(productId => {
      const tempTicket = findAccessCodeExisting(Array.from(ticketsToQuantity.keys()), productId);

      if (!tempTicket) {
        if (foundTicket) {
          return setPromoError('');
        }
        setPromoError('Access code is not valid. Please try again.');
        recordError(new Error('No temp ticket found after access code applied'), {
          originatingFunction: 'EventAccessCodeHelper-createPromo',
          customMessage: 'Mismatch between applied promo code and available tickets',
          errorGroup: NEW_RELIC_ERROR_GROUPS.AccessAndPromoCodes,
          data: {
            ticketsToQuantity,
            productId,
            productIds,
            appliedPromo,
            code,
            promoCode
          }
        });
      } else if (tempTicket.limit === 0) {
        setPromoError('Access codes can’t be applied to sold out tickets.');
      } else if (prodIdsToAccessCodes.has(productId)) {
        if (code === prodIdsToAccessCodes.get(productId)?.code) {
          setPromoError('Access code has already been applied.');
        } else {
          setPromoError('Access code already applied to ticket.');
        }
      } else {
        setProdIdsToAccessCodes((prevState: Map<number, AccessCodeInformation>) =>
          new Map<number, AccessCodeInformation>(prevState).set(productId, {
            code
          })
        );
        setTicketsToQuantity((prevState: Map<ProductSeating, number>) => {
          const tempMap = new Map<ProductSeating, number>(prevState);
          if (tempMap.get(tempTicket) === 0) {
            tempMap.delete(tempTicket);
            tempMap.set({ ...tempTicket }, 0);
          }
          return tempMap;
        });

        setPromoCode('');
        setPromoError('');
      }
    });
  }
};

export const removePromo = async (
  code: string,
  prodIdsToAccessCodes: Map<number, AccessCodeInformation>,
  ticketsToQuantity: Map<ProductSeating, number>,
  setProdIdsToAccessCodes: Function,
  trackPromoteCodeRemoved: Function,
  promoId: string,
  setTicketsToQuantity: Function,
  setPromoCode: Function,
  setPromoError: Function
) => {
  const productIds = Array.from(prodIdsToAccessCodes.entries()).reduce((productIds, prodIdToAccessCode) => {
    return prodIdToAccessCode[1].code === code ? [...productIds, prodIdToAccessCode[0]] : productIds;
  }, [] as number[]);
  const deletePromises = Array.from(prodIdsToAccessCodes.values()).map(accessCode => {
    if (accessCode.id) {
      deletePromoLock(accessCode.id!);
    }
  });
  if (deletePromises.length > 0) await Promise.all(deletePromises);

  await deletePromoLock(promoId);
  productIds.forEach(productId => {
    const tempTicket: ProductSeating = find(Array.from(ticketsToQuantity.keys()), function (ticket: ProductSeating) {
      if (ticket.id.toString() === productId.toString()) {
        return ticket;
      }
    }) as ProductSeating;
    setProdIdsToAccessCodes((prevState: Map<number, AccessCodeInformation>) => {
      const prodIdsToAccessCodes = new Map<number, AccessCodeInformation>(prevState);
      trackPromoteCodeRemoved({
        promotion: {
          codeName: prodIdsToAccessCodes.get(productId)?.code ? prodIdsToAccessCodes.get(productId)?.code || '' : '',
          codeId: promoId || '',
          discount: 0,
          codeAccess: 'access_code'
        },
        promotions: undefined,
        inCart: false
      });
      prodIdsToAccessCodes.delete(productId);
      return prodIdsToAccessCodes;
    });
    setTicketsToQuantity((prevState: Map<ProductSeating, number>) => {
      const tempMap = new Map<ProductSeating, number>(prevState);
      if (tempTicket) {
        tempMap.set(tempTicket, 0);
      }
      return tempMap;
    });
  });
  setPromoCode('');
  setPromoError('');
};

export type LimitValidationType = ({
  ticketId,
  eventId,
  quantity,
  eventDetailCartEvent
}: {
  ticketId: string;
  eventId: string;
  quantity: number;
  eventDetailCartEvent?: EventCart | undefined;
}) => {
  numberTicketLeft: number;
  ticketLeftPopup: number;
  limitType: string;
  removeThisTicket: boolean;
};

export const handleTicketLimits = async (
  ticket: ProductSeating,
  quantity: number,
  codeId: string,
  isSeason: boolean,
  eventCart: EventCart,
  packCount: number,
  changeTicketQuantity: (ticket: ProductSeating, newQuantity: number) => void,
  limitValidation: LimitValidationType
) => {
  const {
    numberTicketLeft,
    removeThisTicket: isOutOfStock,
    limitType
  } = await limitValidation({
    ticketId: ticket.id,
    eventId: eventCart.id,
    quantity: quantity,
    eventDetailCartEvent: convertEventSalesInfo(eventCart, ticket, isSeason)
  });

  if (!isEmpty(codeId) && codeId) {
    const res = await updatePromoLock(codeId || '', quantity);
    const { quantityLocked = 0, quantityRequested = 0 } = res && 'quantityLocked' in res ? res : {};

    const overLimit = quantityLocked < quantityRequested;
    if (overLimit && quantityLocked < (numberTicketLeft || quantity)) {
      return {
        type: ORDER_LIMIT,
        adjustedQuantity: quantityLocked,
        isPromotionRequired: true,
        isOutOfStock,
        onConfirm: async () => {
          changeTicketQuantity(ticket, getPackCountQuantity(quantityLocked, packCount));
        }
      };
    }
  }
  if (numberTicketLeft > 0 || isOutOfStock) {
    if (ticket.isPromotionRequired && codeId) updatePromoLock(codeId || '', numberTicketLeft);
    return {
      type: limitType,
      adjustedQuantity: limitType === 'ORDER_LIMIT' ? eventCart.ticketLimitPerOrder : numberTicketLeft,
      isPromotionRequired: false,
      isOutOfStock,
      onConfirm: async () => {
        changeTicketQuantity(ticket, numberTicketLeft);
      }
    };
  }
  return {
    type: ORDER_LIMIT,
    adjustedQuantity: 0,
    isOutOfStock: false,
    isPromotionRequired: false,
    onConfirm: async () => {}
  };
};
