import React from 'react';
import pluralize from 'pluralize';

import { StyledText } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';
import { styles } from './LowTicketWarning.styles';
import styled from '@gf/cross-platform-lib/styled-components';

interface LowTicketWarningProps {
  isEvent: boolean;
  limit: number;
}

const LowTicketWarningContainer = styled(View)`
  ${styles.lowTicketWarningContainer}
`;

export const LowTicketWarning = ({ isEvent, limit }: LowTicketWarningProps) => {
  const ticketOrTickets = pluralize('Ticket', limit);

  return (
    <LowTicketWarningContainer isEvent={isEvent} soldOut={limit === 0}>
      <StyledText typeToken='label01'>
        {limit === 0 ? 'Sold Out' : `${limit} ${isEvent ? 'Event ' : ''}${ticketOrTickets} Left`}
      </StyledText>
    </LowTicketWarningContainer>
  );
};

export default LowTicketWarning;
