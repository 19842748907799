import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  container: css`
    ${baseStyles.container}
  `,
  iconWrapper: css`
    ${baseStyles.iconWrapper}
  `
};
