import React from 'react';

import { StyledButton } from '../StyledButton';
import { View } from 'react-native';
import { useScrollView } from '@gf/cross-platform-lib/providers';

export const GoToTopButton = () => {
  const { scrollToTop } = useScrollView();

  return (
    <View style={{ alignSelf: 'center' }}>
      <StyledButton
        width={'137px'}
        height={'32px'}
        buttonType={'ghost--border'}
        size={'sm'}
        title={'Go to top'}
        accessibilityLabel={'Go to top'}
        onPress={scrollToTop}
      />
    </View>
  );
};

export default GoToTopButton;
