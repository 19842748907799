import React, { useState } from 'react';
import { View } from 'react-native';

import { styles } from './ReservedSeatingTicket.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { Carbonicons, StyledButton, StyledText } from '@gf/cross-platform-lib/components';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { Event } from '@gf/cross-platform-lib/interfaces';
import { useMediaQuery, useNavigate, useReservedSeating } from '@gf/cross-platform-lib/hooks';
import { SeatHeld, SeatSelectionCountdown } from '@gf/cross-platform-lib/modules/seat-selection/components';
import { groupReservedTicketsByCategory } from '@gf/cross-platform-lib/modules/seat-selection/utils';

const Container = styled(View)`
  ${styles.container}
`;
const ReservedSeatingWrapper = styled(View)`
  ${styles.reservedSeatingWrapper}
`;
const TextWrapper = styled(View)`
  ${styles.textWrapper}
`;
const TopWrapper = styled(View)`
  ${styles.topWrapper}
`;
const MiddleWrapper = styled(View)`
  ${styles.middleWrapper}
`;
const LeftSideWrapper = styled(View)`
  ${styles.leftSideWrapper}
`;
const ButtonWrapper = styled(View)`
  ${styles.buttonWrapper}
`;
const ShowTicketsWrapper = styled.TouchableOpacity`
  ${styles.showTicketsWrapper}
`;
const DropDownWrapper = styled(View)`
  ${styles.dropDownWrapper}
`;
const IconWrapper = styled(View)`
  ${styles.iconWrapper}
`;
const BottomWrapper = styled(View)`
  ${styles.bottomWrapper}
`;

interface ReservedSeatingTicketProps {
  event: Event;
  isSeason?: boolean;
  expiresAt: string;
  onTimerExpire: () => void;
  schoolId?: string;
  encodedString?: string;
}

export const ReservedSeatingTicket = ({
  event,
  expiresAt,
  onTimerExpire,
  isSeason,
  schoolId,
  encodedString
}: ReservedSeatingTicketProps) => {
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();
  const { reservedSeatsState } = useReservedSeating(event.id);
  const { chartInstance, selectedSeats } = reservedSeatsState;
  const numberOfSeats = selectedSeats.length;
  const [showTickets, setShowTickets] = useState(false);
  const [isEventDetails, setIsEventDetails] = useState(false);

  const cheapestTicketType = event.ticketTypes.reduce((cheapestTicket, currentTicket) => {
    return currentTicket.price < cheapestTicket.price ? currentTicket : cheapestTicket;
  }, event.ticketTypes[0] || null);

  const hasTicketsAvailable =
    event.salesInfo && event.salesInfo.productSalesMap[cheapestTicketType.id].remainingQuantity > 0;

  const reservedTicketsGroupedByCategory = groupReservedTicketsByCategory(selectedSeats);

  const navigateToSeatSelection = () => {
    if (isSeason && encodedString) {
      navigate(
        `${GOFAN_APP_PAGES['season-seat-selection'].getPath(
          event.id
        )}?isSeason=${isSeason}&encodedString=${encodedString}`
      );
    } else if (isSeason && !encodedString) {
      navigate(`${GOFAN_APP_PAGES['season-seat-selection'].getPath(event.id)}?isSeason=${isSeason}`);
    } else if (!isSeason && encodedString) {
      navigate(
        `${GOFAN_APP_PAGES['seat-selection'].getPath(event.id)}?schoolId=${schoolId}&encodedString=${encodedString}`
      );
    } else {
      navigate(`${GOFAN_APP_PAGES['seat-selection'].getPath(event.id)}?schoolId=${schoolId}`);
    }
  };

  const eventKey = isSeason ? `season-${event.id}` : `event-${event.id}`;

  return (
    <Container>
      <ReservedSeatingWrapper isMobile={isMobile}>
        <TopWrapper>
          <SeatSelectionCountdown expiresAt={expiresAt} onTimerExpire={onTimerExpire} />
        </TopWrapper>
        <MiddleWrapper isMobile={isMobile}>
          <LeftSideWrapper isMobile={isMobile}>
            <TextWrapper isMobile={isMobile}>
              <StyledText typeToken='body02'>Reserved seating</StyledText>
              <StyledText typeToken='body01'>{numberOfSeats} seats selected</StyledText>
            </TextWrapper>
            <ShowTicketsWrapper
              isMobile={isMobile}
              onPress={() => {
                setShowTickets(!showTickets);
                setIsEventDetails(!isEventDetails);
              }}
            >
              {showTickets ? (
                <DropDownWrapper>
                  <StyledText typeToken='body01' color='rgb(82, 82, 82)'>
                    hide
                  </StyledText>
                  <IconWrapper>
                    <Carbonicons color='#282828' name={'chevron-up'} size={20} />
                  </IconWrapper>
                </DropDownWrapper>
              ) : (
                <DropDownWrapper>
                  <StyledText typeToken='body01' color='rgb(82, 82, 82)'>
                    show
                  </StyledText>
                  <IconWrapper>
                    <Carbonicons color='#282828' name={'chevron-down'} size={20} />
                  </IconWrapper>
                </DropDownWrapper>
              )}
            </ShowTicketsWrapper>
          </LeftSideWrapper>
          <ButtonWrapper isMobile={isMobile}>
            <StyledButton
              title='Change seats'
              accessibilityLabel='Change seats'
              size={'lg'}
              disabled={!hasTicketsAvailable}
              buttonType='tertiary'
              padding='0px'
              height='40px'
              backgroundColor='#FFFFFF'
              onPress={() => {
                navigateToSeatSelection();
                setIsEventDetails(false);
                setShowTickets(false);
              }}
            />
          </ButtonWrapper>
        </MiddleWrapper>
        {showTickets ? (
          <BottomWrapper>
            {Object.entries(reservedTicketsGroupedByCategory).map(([category, seats]) => (
              <SeatHeld
                isMobile={isMobile}
                category={category}
                seats={seats}
                chartInstance={chartInstance!}
                key={seats[0].id}
                isEventDetails={isEventDetails}
                tickets={event.ticketTypes}
                eventKey={eventKey}
                eventId={event.id}
                financialSchoolState={event.financialSchoolState}
              />
            ))}
          </BottomWrapper>
        ) : null}
      </ReservedSeatingWrapper>
    </Container>
  );
};

export default ReservedSeatingTicket;
