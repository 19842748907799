import React, { forwardRef } from 'react';

import { HorizontalRow, StyledText } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';

import { styles } from './EventDetails.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { EventDescription } from '../EventDescription';

const EventDetailsContainer = styled(View)`
  ${styles.eventDetailsContainer}
`;

export type EventDetailsProps = {
  description?: string;
};

export const EventDetails = forwardRef(({ description }: EventDetailsProps, ref) => {
  return (
    <EventDetailsContainer ref={ref} collapsable={false}>
      <HorizontalRow />
      <StyledText typeToken={'heading02'} textAlign={'left'}>
        Event Details
      </StyledText>
      {description ? <EventDescription description={description} /> : null}
    </EventDetailsContainer>
  );
});
EventDetails.displayName = 'EventDetails';

export default EventDetails;
