import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  descriptionContainer: css`
    ${baseStyles.descriptionContainer}
  `,
  expandButtonContainer: css`
    ${baseStyles.expandButtonContainer}
  `,
  overflowWrapper: css`
    ${baseStyles.overflowWrapper}
  `,
  expandButton: css`
    ${baseStyles.expandButton}
  `
};
